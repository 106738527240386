import { INavLinkGroup } from "@fluentui/react";
//TODO: When Fluent UI 9 implements Navbar this should be updated from 8 reference

const NavbarLinks: INavLinkGroup[] = [
	{
		links: [
			{
				name: "Home",
				url: "/"
			},
			{
				name: "Games",
				url: "/games"
			},
			{
				name: "Players",
				url: "/players"
			},
			{
				name: "Reviews",
				url: "/reviews"
			},
			{
				name: "About",
				url: "/about"
			},
			{
				name: "FAQ",
				url: "/faq"
			},
			{
				name: "Contact",
				url: "/contact"
			}
		]
	}
];

export default NavbarLinks;
