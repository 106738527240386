import {
	Button,
	Dialog, DialogBody, DialogSurface, DialogTitle, DialogTrigger, DialogContent, Title2
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlatformLibrary from "../../Media/PlatformLibrary";
import { Platform } from "../../Media/Types";
import { apiPlayerDetailsVideoGame } from "../../Redux/Types";

export type AddPlayerGameModalProps = {
	showModal: boolean,
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
	platform: Platform,
	playerGames: apiPlayerDetailsVideoGame[] | undefined
}

const AddPlayerGameModal = (props: AddPlayerGameModalProps) => {

	return (
		<Dialog
			open={props.showModal}
			onOpenChange={(event, data) => props.setShowModal(data.open)}
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action="close">
								<Button
									appearance="subtle"
									aria-label="close"
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<FontAwesomeIcon style={{ paddingRight: '5px' }} icon={props.platform.FaIconName} />
							<Title2>{props.platform.name} Games</Title2>
						</div>
					</DialogTitle>
					<DialogContent>
						<PlatformLibrary platform={props.platform} currentPlayersLibrary={props.playerGames} />
					</DialogContent>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);

}

export default AddPlayerGameModal;