import { Button, Spinner, Tooltip } from "@fluentui/react-components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useFetchPlayerPlatformsQuery } from "../Api/Players/playersSlice";
import { Platform } from "../Media/Types";
import AddPlatformDialog from "./AddPlatform";
import GameLibrary from "./GameLibrary";

export type PlayerPlatformsProps = {
	username: string,
	isEditable: boolean
}

const PlayerPlatforms = (props: PlayerPlatformsProps) => {

	const { data: playerPlatforms, isLoading: platformsLoading } = useFetchPlayerPlatformsQuery(props.username);
	const [showAddPlatform, setShowAddPlatform] = useState(false);
	const platformRefs = useRef<Array<HTMLDivElement | null>>([]);

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "3px" }}>

				{props.isEditable &&
					<>
						<Tooltip content="Add Console" relationship="label">
							<Button
								type="button"
								id="add-console-btn"
								className="btn btn-success"
								appearance="primary"
								onClick={(ev) => setShowAddPlatform(true)}
							>
								<FontAwesomeIcon icon={faPlus} />
								Platform
							</Button>
						</Tooltip>
						<AddPlatformDialog
							isOpen={showAddPlatform}
							setIsOpen={setShowAddPlatform}
							playerPlatforms={playerPlatforms}
							platformRefs={platformRefs}
							username={props.username}
						/>
					</>
				}
			</div>

			<div style={{marginTop: "15px"}}>
				{
					!platformsLoading ?
						playerPlatforms?.map((console, index: number) => (
							<div key={`${console.name.replace(' ', '')}-${index}-container`} ref={el => platformRefs.current[index] = el}>
								<GameLibrary
									key={`${console.name.replace(' ', '')}-${index}`}
									username={props.username}
									console={new Platform(console.name)}
									isEditable={props.isEditable}
								/>
							</div>
						))
						:
						<Spinner />
				}
			</div>

		</>
	)
}

export default PlayerPlatforms;