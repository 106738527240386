function Contact(): any {

    return (
        <>
            <h3>Contact Peer Gaming</h3>
            <hr />
            <p>At this time we don't care what you think. Do not contact.</p>
        </>

    );
}

export default Contact;