import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Option, Button, Combobox, ComboboxProps, useId, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Dismiss12Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
	root: {
		// Stack the label above the field with a gap
		display: "grid",
		gridTemplateRows: "repeat(1fr)",
		justifyItems: "start",
		...shorthands.gap("2px"),
		maxWidth: "400px",
	},
	tagsList: {
		listStyleType: "none",
		marginBottom: tokens.spacingVerticalXXS,
		marginTop: 0,
		paddingLeft: 0,
		display: "flex",
		flexWrap:"wrap",
		gridGap: tokens.spacingHorizontalXXS,
	},
});

export type TagComboboxProps = {
	comboboxProps: Partial<ComboboxProps>,
	options: string[],
	selectedOptions?: string[],
	onChange?: (selectedOption: string[]) => void
}

export type TagComboboxRef = {
	getSelectedValue: () => string[]
}

const TagCombobox = forwardRef<TagComboboxRef, TagComboboxProps>((props, ref) => {
	const styles = useStyles();
	const { onChange } = props;

	const comboId = useId(`${props.comboboxProps.id}-multi`);
	const selectedListId = `${comboId}-selection`;

	const selectedListRef = useRef<HTMLUListElement>(null);
	const comboboxInputRef = useRef<HTMLInputElement>(null);

	useImperativeHandle(ref, () => ({
		getSelectedValue: () => {
			return selectedOptions;
		}
	}));

	const [selectedOptions, setSelectedOptions] = useState<string[]>(props.selectedOptions ?? []);

	const onPlatformSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
		setSelectedOptions(data.selectedOptions);		
	}

	const onPlatformTagClick = (option: string, index: number) => {
		setSelectedOptions(selectedOptions.filter((platform) => platform !== option));

		const indexToFocus = index === 0 ? 1 : index - 1;
		const optionToFocus = selectedListRef.current?.querySelector(
			`${comboId}-remove-${indexToFocus}`
		);

		if (optionToFocus) {
			(optionToFocus as HTMLButtonElement).focus();
		} else {
			comboboxInputRef.current?.focus();
		}
	};

	const labelledBy = selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

	useEffect(() => {
		if (onChange) {
			onChange(selectedOptions);
		}
	}, [selectedOptions, onChange]);

	return (
		<div className={styles.root}>
			<label id={comboId}>Platforms</label>
			{
				selectedOptions.length ? (
					<ul
						id={selectedListId}
						className={styles.tagsList}
						ref={selectedListRef}
					>

						<span id={`${comboId}-remove`} hidden>
							Remove
						</span>

						{
							selectedOptions && selectedOptions.map((option, index) => (
								<li key={option}>
									<Button
										id={`${comboId}-remove-${index}`}
										size="small"
										shape="circular"
										appearance="primary"
										icon={<Dismiss12Regular />}
										iconPosition="after"
										onClick={() => onPlatformTagClick(option, index)}
										aria-labelledby={`${comboId}-remove ${comboId}-remove-${index}`}
									>
										{option}
									</Button>
								</li>
							))
						}


					</ul>
				) : null
			}
			<Combobox
				aria-labelledby={labelledBy}
				multiselect={true}
				placeholder="Select one or more platforms"
				selectedOptions={selectedOptions}
				onOptionSelect={onPlatformSelect}
				ref={comboboxInputRef}

			>
				{props.options && props.options.map((option) => (
					<Option key={option}>{option}</Option>
				))}
			</Combobox>
		</div>
	);
});

export default TagCombobox;