import { makeStyles } from "@fluentui/react-components";
import { useParams } from "react-router-dom";
import { useFetchReviewsQuery } from "../Api/Reviews/reviewSlice";
import NotFound from "../Shared/NotFound";
import ReviewBody from "./ReviewBody";
import ReviewFooter from "./ReviewFooter";
import ReviewHeader from "./ReviewHeader";

const useStyles = makeStyles({
	root: {
		position: "relative",
		height: "100%",
		width: "100%"
	},
	header: {
		position: "absolute",
		top: 0,
		width: "100%"
	},
	body: {
		paddingTop: "200px"
	},
	footer: {
		position: "absolute",
		bottom: 0
	}
});

const ReviewPage = () => {
	const styles = useStyles();
	const { reviewid } = useParams();
	const { review, isLoading } = useFetchReviewsQuery({ reviewId: Number(reviewid) }, {
		selectFromResult: ({ data, isLoading, isError }) => ({
			review: data && data.length > 0 ? data[0] : undefined,
			isLoading,
			isError
		})
	});

	return (
		<>
			{
				(!reviewid || (!review && !isLoading)) && <NotFound />
			}
			{
				reviewid && review &&
				<div className={styles.root}>
					<div className={styles.header}>
						<ReviewHeader review={review} />
					</div>

					<div className={styles.body}>
						<ReviewBody review={review} />
					</div>

					<div className={styles.footer}>
						<ReviewFooter />
					</div>

				</div>
			}

		</>
	);
}

export default ReviewPage;