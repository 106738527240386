import { Body1, Image, makeStyles, shorthands, Subtitle2, Subtitle2Stronger, Title2 } from "@fluentui/react-components";
import AzureLogo from "../Assets/Technology/azure-logo.png";
import CSharpLogo from "../Assets/Technology/c-sharp-logo.png";
import CssLogo from "../Assets/Technology/css-logo.png";
import FirebaseLogo from "../Assets/Technology/firebase-logo.png";
import FluentUiLogo from "../Assets/Technology/fluent-ui-logo.png";
import GithubLogo from "../Assets/Technology/github-logo.png";
import HtmlLogo from "../Assets/Technology/html-logo.png";
import JsLogo from "../Assets/Technology/js-logo.png";
import NodeJsLogo from "../Assets/Technology/node-js-logo.png";
import ReactLogo from "../Assets/Technology/react-logo.png";
import ReduxLogo from "../Assets/Technology/redux-logo.png";
import SqliteLogo from "../Assets/Technology/sqlite-logo.png";
import Developer from "../Assets/Developer.jpg";

const useStyles = makeStyles({
	root: {
		width: "100%",
		textAlign: "center",
		...shorthands.margin("auto")
	},
	container: {
		display: "flex",
		flexDirection: "column",
		rowGap: "50px",
		marginBottom: "50px"
	},
	logoContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		columnGap: "15px",
		justifyContent: "center",
		maxWidth: "600px",
		...shorthands.margin("auto")
	},
	logo: {
		objectFit: "contain"
	}
});

const About = () => {

	const styles = useStyles();

	return (
		<div className={styles.root}>
			<Title2>About Peer Gaming</Title2>
			<hr className="divider" />

			<div className={styles.container}>

				<div>
					<Subtitle2Stronger>Peer Gaming is an application designed for gamers to collaborate.</Subtitle2Stronger> <br />
					<div style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
						<Body1>
							Are there more games in the market than you have time to play? Are you interested in what your friends are playing and what they thought to help you decide what to play next?
							Do you want to let your friends know what you've played that they can't miss out on and what they shouldn't waste their money on?
						</Body1>
						<br />
						<Body1>
							Video Games prices have become quite the investment and with more and more titles dropping every month it's hard to decide what to play next.
							Peer Gaming was designed to give players the platform to share their thoughts on games that they play by creating their own Reviews for their peers.
							It also aims to provide a place to research and find the next game that they want to play by seeing what their communities response was. 
						</Body1>
					</div>
					<div style={{ textAlign: "left", maxWidth: "800px", marginTop: "30px", marginLeft: "auto", marginRight: "auto" }}>
						<ul style={{ listStyleType: "none" }}>
							<li><Subtitle2>Getting Started:</Subtitle2></li>
							<li><Body1>1. Create an account to start adding games to your library</Body1></li>
							<li><Body1>2. Post simple or in-depth reviews about games that you've played for your friends to view</Body1></li>
							<li><Body1>3. Follow other players to see what they play and read their reviews to see what they thought</Body1></li>
							<li><Body1>4. Comment to let them know what you thought about their reviews</Body1></li>
							<li><Body1>5. Search for games you might be interested in and see what others have thought</Body1></li>
						</ul>
					</div>
				</div>

				<div>
					<Subtitle2Stronger>Peer Gaming was built using various technologies and services</Subtitle2Stronger>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={AzureLogo} alt="Microsoft Azure" title="Microsoft Azure" />
						<img className={styles.logo} src={CSharpLogo} alt="C#" title="C#" />
						<img className={styles.logo} src={FirebaseLogo} alt="Firebase" title="Firebase" />
						<img className={styles.logo} src={FluentUiLogo} alt="Fluent UI 2" title="Fluent UI 2" />
						<img className={styles.logo} src={GithubLogo} alt="Github" title="Github" />
						<img className={styles.logo} src={HtmlLogo} alt="Html 5" title="Html 5" />
						<img className={styles.logo} src={JsLogo} alt="Javascript/Typescript" title="Javascript/Typescript" />
						<img className={styles.logo} src={CssLogo} alt="CSS 3" title="CSS 3" />
						<img className={styles.logo} src={NodeJsLogo} alt="Node JS" title="Node JS" />
						<img className={styles.logo} src={ReactLogo} alt="React" title="React" />
						<img className={styles.logo} src={ReduxLogo} alt="Redux" title="Redux" />
						<img className={styles.logo} src={SqliteLogo} alt="Sqlite" title="Sqlite" />
					</div>
				</div>


				<div style={{ display: "flex", justifyContent: "center" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<Subtitle2Stronger>About the Developer</Subtitle2Stronger>
						<div style={{ maxWidth: "400px", textAlign: "left" }}>
							<Body1>
								Hi, I'm Sam!
							</Body1>
							<br />
							<Body1>
								As an avid gamer for 25+ years the idea for Peer Gaming is something that sprouted in my adolescent head and continued to grow with me into my adult life.
								Sharing experiences with a game that I play simultaneously with friends and families are what make video games so special.
								Now, as a full-stack developer for almost 10 years, I have the capabilities to make it happen! I hope that other gamers out there are able to enjoy it as much as I have
								building it!
							</Body1>
						</div>

					</div>
					<Image src={Developer} shape="circular" style={{ marginLeft: "35px", height: "150px" }} />
				</div>

			</div>
		</div>

	);
}

export default About;