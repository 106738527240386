const reISO = /^(\d{4})-(\d{2})-(\d{2})$/;

export function filterByValue(data: any[] | undefined, searchValue: string) {
	// Dont initiate search until we have enough to use
	if (data && searchValue.length >= 3) {
		return data.filter(obj => Object.keys(obj).some(key => {
			let searchableValue;
			if (typeof (obj[key]) === 'string') {
				if (reISO.exec(obj[key])) {
					// Key was a date
					searchableValue = new Date(obj[key] + "00:00:00").toLocaleDateString("en-US");
				} else {
					searchableValue = obj[key];
				}
			} else {
				// Cast as string
				searchableValue = String(obj[key]);
			}

			return searchableValue.toLowerCase().includes(searchValue.toLowerCase());
		}));
	}

	return data;
}