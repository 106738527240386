import { useState } from "react";
import {
	tokens,
	Spinner,
	makeStyles,
	Caption1,
	Subtitle1,
	Button,
	shorthands,
	LargeTitle,
	Body2
} from "@fluentui/react-components";
import { useFetchGameDetailsQuery } from "../Api/Games/gamesSlice";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../pgStore";
import GameFormModal from "./Form/GameFormModal";
import BoxArt from "../Shared/BoxArt";
import ReviewToolstrip from "../Shared/ReviewToolstrip";

export default function GameDetails() {
	const styles = useStyles();
	const { gameTitle } = useParams();
	const { data } = useFetchGameDetailsQuery(String(gameTitle));
	const isOwner: boolean = useAppSelector((state) => state.user.username === "SneakySquid");
	const [showGameForm, setShowGameForm] = useState(false);

	return (
		<div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", position: "relative" }}>
			{
				!data &&
				<Spinner />
			}
			{
				data &&
				<div className={styles.root}>
					<BoxArt
						imageSrc={data.boxArtUrl}
						altText={''}
						containerOptions={{
							width: "100%",
							height: "250px"
						}}
						imageOptions={{
							minHeight: "100%"
						}}
						splash
					/>

					<div style={{ display: "flex", columnGap: "15px" }}>


						<div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
							<LargeTitle>{data.title}</LargeTitle>
							<div style={{ display: "flex", columnGap: "4px", flexWrap: "wrap", rowGap: "2px" }}>
								{
									data.availableOnConsoles.map((platform, index) => (
										<>
											<Caption1 key={`${platform.name.replaceAll(" ", "")}`} className={styles.platform}>{platform.name}</Caption1>
										</>
									))
								}
							</div>
							<Subtitle1>Release Date: {data.releaseDate}</Subtitle1>
							<Body2>{data.description}</Body2>
						</div>
						{
							isOwner &&
							<div style={{ marginLeft: "auto" }}>
								<Button
									appearance="subtle"
									onClick={(event) => setShowGameForm(true)}
								>
									Edit
								</Button>
							</div>
						}
					</div>

					<div className={styles.reviews}>
							<ReviewToolstrip
								title={`${data.title} Reviews`}
								gameTitle={data.title}
							/>
					</div>
				</div>
			}
			{
				isOwner && showGameForm &&
				<GameFormModal
					showModal={showGameForm}
					setShowModal={setShowGameForm}
					game={data}
				/>
			}
		</div>
	)
}

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "15px",
		width: "80%",
		minWidth: "400px"
	},
	platform: {
		backgroundColor: tokens.colorBrandBackground,
		textAlign: "center",
		...shorthands.borderRadius("3px"),
		...shorthands.padding("2px", "4px")
	},
	reviews: {
		position: "absolute",
		bottom: "0px"
	}
});