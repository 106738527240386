import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const PeerGamingApiSlice = createApi({
	reducerPath: 'peergamingApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL, 
		prepareHeaders(headers) {
			headers.set("x-pg-api-key", "$2a$11$JVVY5zDfOQhP9VRNO/2yUOQ5ZM0j8vDL1XYWhWEifmionLkh90uYO");
			return headers;
		}
	}),
	tagTypes: [
		'Games', 'Platforms',
		'Reviews', 'Highlights',
		'Player', 'PlayerPlatforms', 'PlayerGames', 'PlayerSocials',
		'Socials',
		'User'
	], 
	endpoints: () => ({})
});