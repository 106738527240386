import { Subtitle1 } from "@fluentui/react-components";

const RecentActivity = () => {

	return (
		<div>
			<Subtitle1>Coming Soon...</Subtitle1>
		</div>
	)
}

export default RecentActivity;