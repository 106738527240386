import { PgFormField } from "../Shared/Types"

export type GameFormState = {
	title: PgFormField<string | undefined>,
	description?: string,
	boxArtUrl?: string,
	availableConsoles?: string[],
	releaseDate?: PgFormField<string | undefined>
}

export type GameFormAction = GameFormStringAction | GameFormFieldAction | GameFormStrArrAction;

export type GameFormStringAction = {
	type: Exclude<GameFormActionType, GameFormActionType.SetReleaseDate | GameFormActionType.SetTitle | GameFormActionType.SetAvailableConsoles>,
	payload: string
}

export type GameFormFieldAction = {
	type: GameFormActionType.SetReleaseDate | GameFormActionType.SetTitle, 
	payload: Partial<PgFormField<string>>
}

export type GameFormStrArrAction = {
	type: GameFormActionType.SetAvailableConsoles,
	payload: string[]
}

export enum GameFormActionType {
	SetTitle,
	SetDescription,
	SetAvailableConsoles,
	SetReleaseDate,
	SetBoxArtUrl
}

