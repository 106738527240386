import { newUserFormState, newUserFormReducerAction, newUserFormReducerActionTypes } from "./Types";

export const initialState: newUserFormState = {
	username: {
		value: undefined,
		validationState: "none",
		validationMessage: undefined
	},
	emailAddress: {
		value: undefined,
		validationState: "none",
		validationMessage: undefined
	},
	password: {
		value: undefined,
		validationState: "none",
		validationMessage: undefined
	}
};

export function newUserFormReducers(state: newUserFormState, action: newUserFormReducerAction) {
	// Reducer Action Type
	switch (action.type) {

		case newUserFormReducerActionTypes.setInputData:
			switch (action.payload.fieldname) {
				case 'username':
					return {
						...state,
						username: {
							...state.username,
							value: action.payload.value
						}
					}

				case 'emailAddress':
					return {
						...state,
						emailAddress: {
							...state.emailAddress,
							value: action.payload.value
						}
					}

				case 'password':
					return {
						...state,
						password: {
							...state.password,
							value: action.payload.value
						}
					}

				default:
					return { ...state };
			}

		case newUserFormReducerActionTypes.setInputValidation:

			switch (action.payload.fieldname) {
				case 'username':
					return {
						...state,
						username: {
							...state.username,
							validationState: action.payload.validationState,
							validationMessage: action.payload.validationMessage
						}
					}

				case 'emailAddress':
					return {
						...state,
						emailAddress: {
							...state.emailAddress,
							validationState: action.payload.validationState,
							validationMessage: action.payload.validationMessage
						}
					}

				case 'password':
					return {
						...state,
						password: {
							...state.password,
							validationState: action.payload.validationState,
							validationMessage: action.payload.validationMessage
						}
					}

				default:
					return { ...state };
			}

		default:
			return { ...state };
	}

}