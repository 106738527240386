import {
	Display, Dropdown, Field, Label, makeStyles, Option, shorthands, Slider, Switch, Title2, tokens
} from "@fluentui/react-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, useEffect, useState } from "react";
import { Platform } from "../../Media/Types";
import { consoleList } from "../../Redux/Types";
import BoxArt from "../../Shared/BoxArt";
import { NewReviewFormReducerActionTypes, NewReviewFormState } from "../Types";

const useStyles = makeStyles({
	root: {
		display: "flex",
		columnGap: "35px",
		width: "100%",
		flexWrap: "wrap"
	},
	ratingContainer: {
		display: "flex",
		flexWrap: "wrap",
		minWidth: "385px",
		maxWidth: "600px"
	},
	score: {
		backgroundColor: tokens.colorCompoundBrandBackground,
		height: "65px",
		width: "65px",
		...shorthands.borderRadius("32px"),
		paddingTop: "12px"
	},
	image: {
		minWidth: "250px",
		maxWidth: "250px",
		height: "250px"
	}
});

export type GameRatingProps = {
	id: string,
	/** Form Context of the New Review form */
	formContext: NewReviewFormState,
	/** Dispatch instance to manipulate Form Context */
	dispatchFormContext: Dispatch<any>
}

const GameRating = (props: GameRatingProps) => {
	const styles = useStyles();

	const [reviewPlatform, setReviewPlatform] = useState<Platform | null>(null);

	useEffect(() => {
		if (props.formContext.platform?.name) {
			setReviewPlatform(new Platform(props.formContext.platform.name));
		}
	}, [props.formContext.platform]);

	return (
		<div className={styles.root}>

			<BoxArt
				imageSrc={props.formContext.game?.boxArtUrl}
				altText={`${props.formContext.game?.title} Box Art`}
				imageOptions={{
					height: "100%"
				}}
				containerOptions={{
					height: "250px",
					width: "250px"
				}}
			/>

			<div style={{ flexGrow: "4" }}>
				<div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", rowGap: "15px" }}>

					<div style={{ display: "flex", columnGap: "14px" }}>
						<Display>{props.formContext.game?.title}</Display>
						{reviewPlatform &&
							<div>
								<FontAwesomeIcon
									icon={reviewPlatform.FaIconName}
									title={`Reviewed on ${reviewPlatform.name}`}
								/>
							</div>
						}
					</div>
					{
						props.formContext.game && !reviewPlatform &&
						<div>
							<Label htmlFor={`${props.id}-platform-dropdown`} style={{ textAlign: "left" }}>Select a Platform</Label>
							<Dropdown
								id={`${props.id}-platform-dropdown`}
								placeholder="Select a Platform"
								selectedOptions={[""]}
								onOptionSelect={(event, data) => {
									if (data.optionValue) {
										const selectedPlatform: consoleList = { name: data.optionValue };
										props.dispatchFormContext({ type: NewReviewFormReducerActionTypes.setPlatform, payload: selectedPlatform });
									}
								}}
							>
								{
									props.formContext.game.availableOnConsoles?.map((platform, index) =>
										<Option key={index} text={platform.name} value={platform.name}>
											{platform.name}
										</Option>
									)
								}
								{
									props.formContext.game.availableOnConsoles && props.formContext.game.availableOnConsoles.length === 0 ? (
										<Option key="no-results" text="">
											No Results Found
										</Option>
									) : null
								}
							</Dropdown>
						</div>
					}

					<div className={styles.ratingContainer}>
						<Field label="Rating" style={{ width: "80%" }}>
							<Slider
								min={0}
								max={10}
								step={0.5}
								onChange={(event, data) => props.dispatchFormContext({ type: NewReviewFormReducerActionTypes.setRating, payload: data.value })}
								value={props.formContext.rating}
							/>
						</Field>
						<div style={{ display: "flex", textAlign: "center", paddingTop: "25px" }}>
							<div className={styles.score}>
								<Title2>{props.formContext.rating}</Title2>
							</div>
						</div>
					</div>

					<div>
						<Switch
							label="Recommended"
							labelPosition="before"
							title="Would you recommend your friends play this game?"
							onChange={(event, data) => props.dispatchFormContext({ type: NewReviewFormReducerActionTypes.setRecommendation, payload: data.checked })}
							value={props.formContext.recommended?.toString() ?? "false"}
						/>
					</div>

				</div>
			</div>

		</div>
	);
}

export default GameRating;