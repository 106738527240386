import {
	Badge, Body1, Caption1,
	Card, CardHeader, CardFooter, CardPreview,
	makeStyles, shorthands
} from "@fluentui/react-components"
import { InfoCardPostType, InfoCardProps } from "./Types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import './Shared.css';

const useStyles = makeStyles({
	card: {
		...shorthands.margin("auto"),
		width: "720px",
		maxWidth: "100%",
	},
	image: {
		maxHeight: "300px",
		width: "auto",
		objectFit: "cover"
	},
	header: {
		display: "flex",
		justifyContent: "space-between"
	}
});

const InfoCard = (props: InfoCardProps) => {
	const styles = useStyles();

	const InfoBadge = () => {

		return (
			<div>
				{
					props.types?.includes(InfoCardPostType.Hot) &&
					<Badge color="severe" shape="rounded" appearance="tint">
						Hot Game
					</Badge>
				}
				
				{
					props.types?.includes(InfoCardPostType.NewRelease) &&
					<Badge color="success" shape="rounded" appearance="tint">
						New Game
					</Badge>
				}
				
				{
					props.types?.includes(InfoCardPostType.Update) &&
					<Badge color="brand" shape="rounded" appearance="tint">
						Updated
					</Badge>
				}
				
			</div>
			)
	}

	return (
		<Card className={styles.card}>

			<CardHeader
				image={
					<img
						src=""
						alt=""
					/>
				}
				header={
					<Body1 className={styles.header}>
							<InfoBadge />
							<b>{props.title}</b>
							<FontAwesomeIcon icon={['fab', 'playstation']} />
					</Body1>
				}
				description={
					<Caption1>
						{props.subtitle}
					</Caption1>
				}
			>
			</CardHeader>

			<CardPreview>
				<img
					className={styles.image}
					src={props.imageSource}
					alt="Marvel Snap"
				/>
			</CardPreview>

			<CardFooter>
				{props.body}
			</CardFooter>

		</Card>


	);
}

export default InfoCard;