import { VideoGameListItem } from "../Api/Games/types"
import { Review } from "../Api/Reviews/reviewSlice"
import { consoleList } from "../Redux/Types"

export type NewReviewFormState = {
	id: number,
	game: VideoGameListItem | null,
	platform: consoleList | null,
	title: string,
	details: string,
	rating: number,
	recommended: boolean,
	highlights: ReviewHighlight[]
}

export type SetGameAction = {
	type: NewReviewFormReducerActionTypes.setGame,
	payload: VideoGameListItem
}

export type BindReviewAction = {
	type: NewReviewFormReducerActionTypes.bind,
	payload: Review
}

export type SetPlatformAction = {
	type: NewReviewFormReducerActionTypes.setPlatform,
	payload: consoleList
}

export type SetStringAction = {
	type: NewReviewFormReducerActionTypes.setTitle | NewReviewFormReducerActionTypes.setDetails,
	payload: string
}

export type SetNumberAction = {
	type: NewReviewFormReducerActionTypes.setRating,
	payload: number
}

export type SetBooleanAction = {
	type: NewReviewFormReducerActionTypes.setRecommendation,
	payload: boolean
}

export type AddHighlightAction = {
	type: NewReviewFormReducerActionTypes.addHighlight,
	payload: ReviewHighlight
}

export type HighlightAction = {
	type: NewReviewFormReducerActionTypes.updateHighlight | NewReviewFormReducerActionTypes.removeHighlight,
	payload: { index: number, rating: number | null | undefined }
}

export type NewReviewFormReducerAction =
	{ type: NewReviewFormReducerActionTypes.reset } | BindReviewAction | SetGameAction | SetPlatformAction | SetStringAction | SetNumberAction | SetBooleanAction | AddHighlightAction | HighlightAction;

export enum NewReviewFormReducerActionTypes {
	reset,
	bind,
	setGame,
	setPlatform,
	setTitle,
	setDetails,
	setRating,
	setRecommendation,
	addHighlight,
	updateHighlight,
	removeHighlight
}

export type ReviewHighlight = {
	type: string,
	rating: number
}