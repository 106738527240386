import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import ApplicationRouter from './ApplicationRouter';

library.add(fab, fas);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCpM7wLeFi5WQCFyVhFdoqX-uICoSJlXPI",
    authDomain: "peer-gaming.firebaseapp.com",
    projectId: "peer-gaming",
    storageBucket: "peer-gaming.appspot.com",
    messagingSenderId: "46374337197",
    appId: "1:46374337197:web:745505b871956d027c6424",
    measurementId: "G-JNQ7FTMTG7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const Application = () => {

    return (
        <ApplicationRouter />
    );
}

export default Application;
