import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, MouseEvent, useImperativeHandle, useRef, useState } from 'react';
import { VideoGameListItem } from '../Api/Games/types';

import "./Media.scss";

export type GameCardProps = {
	/** Game to be displayed as the Game Card component */
	game: VideoGameListItem,
	/** Function to be called from the onclick event of the Card. MouseEvent<HtmlDivElement> is passed in. */
	cardClickEvent?: Function,
	/** Boolean stating whether this game is owned by the currently logged in player */
	gameOwnedByPlayer?: boolean | undefined,
	/** Text to be displayed in the feedback ticker */
	feedbackText?: string | undefined,
	index?: number | undefined
}

export type GameCardRef = {
	/** Invoke method for user feedback when configured */
	triggerFeedback: () => void
}

const GameCard = forwardRef<GameCardRef, GameCardProps>((props, ref) => {

	const gameCardRef = useRef(null);

	useImperativeHandle(ref, () => ({
		/** Method used to indicate the GameCard was successfully clicked. */
		triggerFeedback: () => {
			setCardClicked(true);
		}
	}));

	const [cardClicked, setCardClicked] = useState(false);

	const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
		if (props.cardClickEvent) {
			// 0 is a valid index, previously had if(props.index) and 0 doesnt satisfy that
			if (props.index !== undefined) {
				props.cardClickEvent(event, props.index, props.game);
			} else {
				props.cardClickEvent(event, props.game);
			}
		}
	}

	return (
		<div className="game-card-item" onClick={onClickHandler} ref={gameCardRef}>
			<div className="game-card-icon">
				{
					props.game.boxArtUrl &&
					<img src={props.game.boxArtUrl} alt={`${props.game.title.replace(' ', '-')}-box-art`} />
				}
				{
					!props.game.boxArtUrl &&
					<FontAwesomeIcon icon={faQuestionCircle} />
				}
				
			</div>

			<p>{props.game.title}</p>

			{
				props.gameOwnedByPlayer &&

				<div className={classNames("game-card-feedback", "game-card-feedback-owned")}>
					<p>In Library</p>
				</div>
			}

			{
				!props.gameOwnedByPlayer && // and someone should be logged in
				<div className={classNames("game-card-feedback", { "game-card-feedback-clicked": cardClicked && props.feedbackText })}>
					<p>{props.feedbackText}</p>
				</div>
			}

		</div>
	);
});

export default GameCard;