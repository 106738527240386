import { useEffect, useCallback } from 'react'

/** Debouncer used to delay an action such as input text onchange firing too frequently 
	Example Use: 
	useDebouncer(() => {
		//Do Effect
	}, [<dependencies>], <waitTime>);
 */
export default function useDebouncer(effect: () => void, dependencies: any[], delay: number) {
	const callback = useCallback(effect, [effect, dependencies]);

	useEffect(() => {
		const timeout = setTimeout(callback, delay);
		return () => clearTimeout(timeout);
	}, [callback, delay])
}