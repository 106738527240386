import { Button, Divider, makeStyles, Spinner, tokens, Title2, shorthands } from "@fluentui/react-components";
import { useEffect, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendNotification } from "../../Alerts/alertSlice";
import { AddHighlightRequest, AddReviewRequest, useAddReviewMutation, useLazyFetchReviewsQuery } from "../../Api/Reviews/reviewSlice";
import { useAppDispatch, useAppSelector } from "../../pgStore";
import { NewReviewFormReducerActionTypes, ReviewHighlight } from "../Types";
import NewReview from "./NewReview";
import { newReviewFormInitialState, newReviewFormReducer } from "./NewReviewFormReducer";
import ReviewForm from "./ReviewForm";

export default function Review() {
	const styles = useStyles();
	const user = useAppSelector((state) => state.user);
	const [searchParams,] = useSearchParams();
	const reviewId = searchParams.get("review") ? Number(searchParams.get("review")) : undefined;
	const [formContext, dispatchFormContext] = useReducer(newReviewFormReducer, newReviewFormInitialState);
	const [fetchReview, { data: review, isLoading: loadingReview }] = useLazyFetchReviewsQuery();
	const dispatch = useAppDispatch();
	const navigator = useNavigate();

	const [saveReview, { data: addReviewResponse, isSuccess: addReviewIsSuccess, isLoading: saving }] = useAddReviewMutation();

	const submitForm = (event: React.MouseEvent<HTMLButtonElement>) => {

		const request: AddReviewRequest = {
			id: formContext.id,
			rating: formContext.rating,
			title: formContext.title,
			body: formContext.details,
			recommended: formContext.recommended,
			playerUsername: String(user.username),
			platformName: formContext.platform?.name ?? '',
			videoGameTitle: formContext.game!.title,
			highlights: formContext.highlights.map((item: ReviewHighlight) => {
				return { highlight: item.type, rating: item.rating } as AddHighlightRequest
			})
		};

		saveReview(request);

	}

	useEffect(() => {
		if (addReviewIsSuccess && addReviewResponse?.id) {
			dispatch(sendNotification({ appearance: "inverted", intent: "success", message: "Review Saved Successfully! " }));
			navigator(`/reviews/${addReviewResponse?.id}`);
		}
	}, [addReviewIsSuccess, dispatch, navigator, addReviewResponse]);

	useEffect(() => {
		if (reviewId) {
			fetchReview({ reviewId });
		}
	}, [reviewId, fetchReview]);

	useEffect(() => {
		if (review && review.length > 0) {
			dispatchFormContext({ type: NewReviewFormReducerActionTypes.bind, payload: review[0] });
		}
	}, [review]);

	//<div>
	//	<div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
	//		<Button
	//			icon={<Dismiss24Regular />}
	//			appearance="subtle"
	//			style={{ height: "35px" }}
	//			title="Select another game to review"
	//			onClick={(event) => props.dispatchFormContext({ type: NewReviewFormReducerActionTypes.reset })}
	//		>
	//		</Button>
	//	</div>
	//</div>

	return (
		<div className={styles.root}>

			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<Title2>Review</Title2>
				<Title2>By {user.username}</Title2>
			</div>

			<Divider style={{ padding: "20px 0" }} />

			{
				(loadingReview) &&
				<Spinner appearance="inverted" size="large" />
			}

			{
				!reviewId && !formContext.game &&
				<NewReview formContext={formContext} formDispatch={dispatchFormContext} />
			}

			{
				!loadingReview && formContext.game &&
				<>
					<ReviewForm formContext={formContext} formDispatch={dispatchFormContext} />
					<Button
						type="button"
						appearance="primary"
						style={{
							alignSelf: "flex-end"
						}}
						onClick={submitForm}
						disabled={saving}
					>
						{!saving ? "Save" : <Spinner size="extra-small" appearance="inverted" />}
					</Button>
				</>
			}


		</div >
	)
}

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "15px"
	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		rowGap: "25px"
	},
	formHeader: {
		display: "flex",
		columnGap: "35px",
		width: "100%"
	},
	formSection: {
		display: "flex",
		width: "100%",
		flexWrap: "wrap",
		columnGap: "35px",
		rowGap: "15px"
	},
	inputWithLabel: {
		display: "flex",
		flexDirection: "column",
		...shorthands.gap("2px"),
		maxWidth: "400px"
	},
	gameItem: {
		display: "flex",
		columnGap: "5px",
		alignItems: "center"
	},
	gameDropdown: {
		display: "flex",
		alignItems: "center",
		columnGap: "6px"
	},
	highlightsContainer: {
		display: "flex",
		rowGap: "3px",
		flexWrap: "wrap",
		columnGap: "5px",
		alignItems: "flex-end"
	},
	highlights: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		columnGap: "3px",
		backgroundColor: tokens.colorBrandBackgroundStatic,
		...shorthands.padding("2px", "10px"),
		...shorthands.borderRadius("5px"),
		width: "200px",
		maxHeight: "40px"
	},
	highlightRating: {
		width: "55px"
	},
	reviewBodyContainer: {
		width: "100%"
	},
	reviewBody: {
		width: "100%",
		height: "250px"
	}
});