import { useState } from "react";
import { Outlet } from "react-router-dom";
import NotificationHub from "../Layout/Alerts/NotificationHub";
import SideBar from "../Layout/SideBar";
import './App.scss';

const ApplicationRoot = () => {
	const [navOpen, setNavOpen] = useState(true);

	return (
		<div id="application-root">
			<SideBar isOpen={navOpen} toggle={setNavOpen} />
			<div id="content" style={{position:"relative"}} className={!navOpen ? "focused" : ""}>
				<Outlet />
				<NotificationHub />
			</div>
		</div>
	)
}

export default ApplicationRoot;