import { Field, Input, Label, makeStyles, shorthands, Spinner, Textarea, tokens } from "@fluentui/react-components";
import { AddCircle32Filled } from "@fluentui/react-icons";
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from "react";
import { useAddBoxArtImageMutation, useFetchPlatformsQuery } from "../../Api/Games/gamesSlice";
import { VideoGame } from "../../Api/Games/types";
import BoxArt from "../../Shared/BoxArt";
import TagCombobox, { TagComboboxProps, TagComboboxRef } from "../../Shared/TagCombobox";
import { GameFormActionType, GameFormState } from "../types";
import { gameFormReducer, isValid } from "./GameFormReducer";

export type GameFormRef = {
	getFormValues: () => GameFormState,
	isValid: () => boolean
}

export type GameFormProps = {
	videoGame?: VideoGame,
	isSaving?: boolean
}

const GameForm = forwardRef<GameFormRef, GameFormProps>((props, ref) => {
	const styles = useStyles();
	const [addBoxArt, { isLoading: uploadingBoxArt, isSuccess: boxArtUploaded, data: boxArtUrl }] = useAddBoxArtImageMutation();
	const { data: platforms } = useFetchPlatformsQuery();


	const initialFormState: GameFormState = {
		title: {
			value: props.videoGame?.title ?? ""
		},
		description: props.videoGame?.description,
		releaseDate: {
			value: props.videoGame?.releaseDate,
			visited: false
		},
		boxArtUrl: props.videoGame?.boxArtUrl,
		availableConsoles: props.videoGame?.availableOnConsoles ? props.videoGame.availableOnConsoles.map((item) => item.name) : []
	}
	const [formContext, dispatchFormContext] = useReducer(gameFormReducer, initialFormState);

	useImperativeHandle(ref, () => ({
		getFormValues: () => formContext,
		isValid: () => isValid(formContext, dispatchFormContext)
	}));

	const hiddenFileInput = useRef<HTMLInputElement>(null);
	const boxArtOnChange = () => {
		if (hiddenFileInput.current?.files) {
			const image: Blob = hiddenFileInput.current.files[0];
			let formData = new FormData();
			formData.append("boxArtBlob", image);
			addBoxArt(formData);
		}
	}

	const comboboxRef = useRef<TagComboboxRef>(null);
	const tagComboboxProps = {
		comboboxProps: {
			id: 'platforms',
			disabled: props.isSaving
		},
		options: platforms?.map((platform) => platform.name) ?? [],
		selectedOptions: props.videoGame?.availableOnConsoles ? props.videoGame?.availableOnConsoles.map((platform) => platform.name) : undefined,
		onChange: (values) => dispatchFormContext({ type: GameFormActionType.SetAvailableConsoles, payload: values})
	} as TagComboboxProps;

	useEffect(() => {
		if (boxArtUrl) {
			dispatchFormContext({ type: GameFormActionType.SetBoxArtUrl, payload: boxArtUrl.url });
		}
	}, [boxArtUploaded, boxArtUrl]);

	return (
		<div className={styles.root}>

			<div className={styles.boxArtContainer}>
				{
					formContext.boxArtUrl ?
						<BoxArt
							imageSrc={formContext.boxArtUrl}
							altText="Box Art"
							containerOptions={{ width: "100%", height: "200px" }}
						/>
						:
						<>
							{
								uploadingBoxArt ?
									<Spinner size="large" className={styles.addBoxArtBtn} />
									:
									<>
										<Label htmlFor="boxArtFileInput" className={styles.addBoxArtBtn} title="Add Box Art Image">
											<AddCircle32Filled />
										</Label>
										<input
											id="boxArtFileInput"
											type="file"
											style={{ display: "none" }}
											onChange={boxArtOnChange}
											ref={hiddenFileInput}
										/>
									</>
							}

						</>
				}
			</div>

			<div>

				<div className={styles.input}>
					<Field
						label={"Title"}
						validationState={formContext.title?.validationState}
						validationMessage={formContext.title?.validationMessage}
						required
					>
						<Input
							key="title"
							id="title"
							type="text"
							onChange={(event) => dispatchFormContext({ type: GameFormActionType.SetTitle, payload: { value: event.target.value, validationState: "none", validationMessage: undefined } })}
							value={formContext.title.value}
							disabled={props.videoGame?.title !== undefined || props.isSaving}
							title={props.videoGame?.title !== undefined ? "Title cannot be changed" : "Title of the Game"}
						/>
					</Field>
				</div>

				<div className={styles.input}>
					<Field
						label="Release Date"
						validationState={formContext.releaseDate?.validationState}
						validationMessage={formContext.releaseDate?.validationMessage}
						required
					>
						<Input
							key="releaseDate"
							id="releaseDate"
							name="releaseDate"
							type="date"
							onChange={(event) => dispatchFormContext({ type: GameFormActionType.SetReleaseDate, payload: { value: event.target.value, validationState: "none", validationMessage: undefined } })}
							defaultValue={formContext.releaseDate?.value}
							disabled={props.isSaving}
						/>
					</Field>
				</div>

				<div className={styles.input}>
					<Label htmlFor="description">Description</Label>
					<Textarea
						key="description"
						id="description"
						onChange={(event) => dispatchFormContext({ type: GameFormActionType.SetDescription, payload: event.target.value })}
						value={formContext.description ?? ''}						
						disabled={props.isSaving}
					/>
				</div>

				<div className={styles.input}>
					<TagCombobox
						comboboxProps={tagComboboxProps.comboboxProps}
						options={tagComboboxProps.options}
						selectedOptions={tagComboboxProps.selectedOptions}
						onChange={tagComboboxProps.onChange}
						ref={comboboxRef}
					/>
				</div>

			</div>

		</div>
	)
});

export default GameForm;

const useStyles = makeStyles({
	root: {

	},
	boxArtContainer: {
		width: "100%",
		height: "200px",
		display: "flex",
		...shorthands.overflow("hidden"),
		...shorthands.border("1px", "dotted", tokens.colorBrandBackground)
	},
	addBoxArtBtn: {
		color: tokens.colorBrandBackground,
		...shorthands.margin("auto"),
		":hover": {
			color: tokens.colorBrandBackground2Hover,
			cursor: "pointer"
		}
	},
	input: {
		display: "flex",
		flexDirection: "column",
		...shorthands.padding("4px")
	}
});