import { Body2,  Button, makeStyles, Title1 } from "@fluentui/react-components";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "15px",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
		width: "100%"
	},
	error: {
		color: "red"
	}
});

const ErrorFallback = () => {
	const styles = useStyles();
	const navigator = useNavigate();

	const error = useRouteError();
	let errorMessage: string;

	if (isRouteErrorResponse(error)) {
		errorMessage = error.error?.message || error.statusText;
	} else if (error instanceof Error) {
		errorMessage = error.message;
	} else if (typeof error === 'string') {
		errorMessage = error;
	} else {
		errorMessage = 'Unknown error';
	}

	return (
		<div className={styles.root}>
			<ErrorCircle24Regular className={styles.error} />
			<Title1>Sorry, something went horribly wrong!</Title1>
			<Body2>{errorMessage}</Body2>
			<Button
				appearance="primary"
				onClick={(event) => navigator("/")}
			>
				Return to Safety
			</Button>
		</div>
	);
};

export default ErrorFallback;