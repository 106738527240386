import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"

export type Game = {
    Title: string,
    AvailableOn: IMediaConsole[]
}

export interface IMediaConsole {
	id: string,
    name: string,
    FaIconName: [IconPrefix, IconName]
}

export class Platform implements IMediaConsole {
	constructor(name: string) {
		this.id = name.replaceAll(' ', '');
		this.name = name;

		switch (name) {
			case 'Playstation 5':
			case 'Playstation 4':
			case 'Playstation 3':
			case 'Playstation 2':
			case 'Playstation':				
				this.FaIconName = ['fab', 'playstation'];
				break;

			case 'Xbox Series X':
			case 'Xbox One':
			case 'Xbox 360':
			case 'Xbox':
				this.FaIconName = ['fab', 'xbox'];
				break;

			case 'Nintendo Switch':
				this.FaIconName = ['fas', 'dice-two']
				break;

			case 'PC':
				this.FaIconName = ['fas', 'computer']
				break;

			case 'Mobile':
				this.FaIconName = ['fas', 'mobile']
				break;

			default:
				this.name = name;
				this.FaIconName = ['fas', 'gamepad'];
				break;
		}
		
	}

	id: string
	name: string
	FaIconName: [IconPrefix, IconName]
}