import {
	makeStyles,
	Caption1,
	Button,
	shorthands,
	Card,
	CardFooter,
	CardHeader,
	CardPreview,
	Persona,
	tokens,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { apiPlayerList } from "../Redux/Types";
import BoxArt from "../Shared/BoxArt";
import Space from "../Assets/cool_space.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Platform } from "../Media/Types";
import { useFetchReviewsQuery } from "../Api/Reviews/reviewSlice";

const useStyles = makeStyles({
	root: {
		width: "100%"
	},
	platformList: {
		display: "flex",
		columnGap: "4px",
		overflowX: "hidden"
	},
	platformIcon: {
		backgroundColor: tokens.colorNeutralForeground1,
		width: "35px",
		...shorthands.borderRadius("12px"),
		...shorthands.border("1px", "solid", tokens.colorNeutralBackground1),
		textAlign: "center",
		color: tokens.colorBrandBackground
	}
});

export type PlayerItemProps = {
	player: apiPlayerList
}

const PlayerItem = (props: PlayerItemProps) => {
	const styles = useStyles();
	const navigator = useNavigate();

	const { reviewCount } = useFetchReviewsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			reviewCount: data ? data.filter((review) => review.username === props.player.username).length : 0
		})
	});

	return (
		<Card
			className={styles.root}
		>
			<CardHeader
				image={
					<>
						<Persona
							name={props.player.username}
							primaryText=""
							avatar={{ color: "colorful" }}
						/>
					</>
				}
				header={
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "30px" }}>
						<Caption1><b>{props.player.username}</b></Caption1>
						<div className={styles.platformList}>
							{
								props.player.consoles.map((item, index) =>
									<div key={`${props.player.username}-${item.name.replaceAll(" ", "")}`}>
										<FontAwesomeIcon icon={new Platform(item.name).FaIconName} title={item.name} />
									</div>
								)
							}
						</div>
					</div>
				}
			>
			</CardHeader>

			<CardPreview
				logo={
					<div className={styles.platformIcon} title="Total Reviews">
						<b>{reviewCount}</b>
					</div>
				}
			>
				<BoxArt
					imageSrc={Space}
					altText={`${props.player.username}`}
					thumbnail
				/>
			</CardPreview>

			<CardFooter
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<Button
					title="Coming soon"
					disabled
				>
					Follow
				</Button>
				<Button
					onClick={(event) => navigator(`/Players/${props.player.username}`)}
				>
					View
				</Button>
			</CardFooter>
		</Card>
	);
}

export default PlayerItem;