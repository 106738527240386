import {
	makeStyles,
	Caption1,
	Card,
	CardFooter,
	CardHeader,
	CardPreview,
	tokens,
} from "@fluentui/react-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Platform } from "../Media/Types";
import BoxArt from "../Shared/BoxArt";
import { useNavigate } from "react-router";
import { VideoGameListItem } from "../Api/Games/types";

const useStyles = makeStyles({
	root: {
		minWidth: "225px",
		maxWidth: "250px",
		width: "250px",
		":hover": {
			cursor: "pointer",
			backgroundColor: tokens.colorBrandBackgroundHover
		}
	},
	platformList: {
		display: "flex",
		columnGap: "4px",
		overflowX: "hidden"
	}
});

export type GameItemProps = {
	game: VideoGameListItem
}

const GameItem = (props: GameItemProps) => {
	const styles = useStyles();
	const navigator = useNavigate();

	return (
		<Card
			className={styles.root}
			onClick={() => navigator(`/Games/${props.game.title}`)}
		>
			<CardHeader
				image={null}
				header={
					<div style={{ display: "flex", justifyContent: "space-between", overflow: "hidden", height: "20px", width: "100%" }}>
						<Caption1 title={props.game.title}><b>{props.game.title}</b></Caption1>
					</div>
				}
			>
			</CardHeader>

			<CardPreview>
				<BoxArt
					imageSrc={props.game.boxArtUrl}
					altText={`${props.game.title}`}
					thumbnail
				/>
			</CardPreview>

			<CardFooter
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<div className={styles.platformList}>
					{
						props.game.availableOnConsoles.map((item, index) =>
							<div key={`${props.game.title.replaceAll(" ", "")}-${item.name.replaceAll(" ", "")}`}>
								<FontAwesomeIcon icon={new Platform(item.name).FaIconName} title={item.name} />
							</div>
						)
					}
				</div>
				{/*<Button*/}
				{/*	onClick={(event) => navigator(`/Games/${props.game.title}`)}*/}
				{/*>*/}
				{/*	View*/}
				{/*</Button>*/}
			</CardFooter>
		</Card>
	)
}

export default GameItem;