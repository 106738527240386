import { makeStyles, shorthands, Title2, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
	profileContainer: {
		marginTop: "15px",
		backgroundColor: tokens.colorNeutralBackground1,
		...shorthands.padding("5px", "15px", "35px"),
		...shorthands.borderRadius("5px")
	},
	title: {

	}
});

export type ProfileSectionProps = {
	title: string,
	content: any
}

const ProfileSection = (props: ProfileSectionProps) => {
	const styles = useStyles();

	return (
		<div className={styles.profileContainer} >
			<div className={styles.title}>
				<Title2>{props.title}</Title2>
			</div>
			{
				props.content
			}
		</div>
	)
}

export default ProfileSection;