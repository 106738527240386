import { Button, Divider, Dropdown, Field, Input, Label, Option, SpinButton, Textarea, makeStyles, shorthands, tokens, useId } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { sendNotification } from "../../Alerts/alertSlice";
import { useFetchHighlightsQuery } from "../../Api/Reviews/reviewSlice";
import { useAppDispatch } from "../../pgStore";
import { NewReviewFormReducerAction, NewReviewFormReducerActionTypes, NewReviewFormState, ReviewHighlight } from "../Types";
import GameRating from "./GameRating";

export type ReviewFormProps = {
	formContext: NewReviewFormState,
	formDispatch: React.Dispatch<NewReviewFormReducerAction>
}

export default function ReviewForm(props: ReviewFormProps) {
	const styles = useStyles();
	const id = useId("review-form");
	const { data: highlights } = useFetchHighlightsQuery();
	const dispatch = useAppDispatch();

	return (
		<>
			<GameRating id={id} formContext={props.formContext} dispatchFormContext={props.formDispatch} />

			<Divider />

			<div className={styles.formSection}>

				<div className={styles.inputWithLabel}>
					<Label htmlFor={`${id}-highlights`}>Highlights</Label>
					<Dropdown
						id={`${id}-highlights`}
						placeholder="Select up to 5 Highlights"
						onOptionSelect={(event, data) => {
							data.optionText
								? props.formDispatch({ type: NewReviewFormReducerActionTypes.addHighlight, payload: { type: data.optionText, rating: 5 } })
								: dispatch(sendNotification({ appearance: "primary", intent: "error", message: "An error occurred adding Highlight, invalid Highlight Type." }))
						}}
						disabled={props.formContext.highlights.length > 4}
					>
						{
							highlights?.map((highlight, index) => (
								<Option key={index} text={highlight.highlight} value={index.toString()} title={highlight.description}>
									{highlight.highlight}
								</Option>
							))
						}
					</Dropdown>
				</div>

				<div className={styles.highlightsContainer}>
					{
						props.formContext.highlights.map((highlight: ReviewHighlight, index: number) => {
							return (
								<div key={`${index}-highlight`} className={styles.highlights} >
									<Label htmlFor={`${id}-${highlight.type}`}>
										{highlight.type}:
									</Label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<SpinButton
											value={highlight.rating}
											onChange={(event, data) => props.formDispatch({ type: NewReviewFormReducerActionTypes.updateHighlight, payload: { index: index, rating: data.value } })}
											min={0}
											max={10}
											id={`${id}-${highlight.type}`}
											className={styles.highlightRating}
										/>
										<Button
											appearance="subtle"
											icon={<Dismiss24Regular />}
											onClick={(event) => props.formDispatch({ type: NewReviewFormReducerActionTypes.removeHighlight, payload: { index: index, rating: null } })}
										/>

									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<Divider />

			<Field label="Title" required>
				<Input
					id={`${id}-title`}
					placeholder="Something catchy to summarize"
					appearance="filled-darker"
					type="text"
					style={{ minWidth: "200px", backgroundColor: tokens.colorNeutralBackground1 }}
					onChange={(event) => props.formDispatch({ type: NewReviewFormReducerActionTypes.setTitle, payload: event.target.value })}
					value={props.formContext.title}
				/>
			</Field>

			<div className={styles.reviewBodyContainer}>
				<Field label="Details">
					<Textarea
						id={`${id}-body`}
						placeholder="What's the scoop?"
						appearance="filled-darker"
						resize="none"
						style={{ backgroundColor: tokens.colorNeutralBackground1, height: "280px" }}
						onChange={(event) => props.formDispatch({ type: NewReviewFormReducerActionTypes.setDetails, payload: event.target.value })}
						value={props.formContext.details}
					/>
				</Field>

			</div>
		</>
	);
}


const useStyles = makeStyles({
	root: {

	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		rowGap: "25px"
	},
	formHeader: {
		display: "flex",
		columnGap: "35px",
		width: "100%"
	},
	formSection: {
		display: "flex",
		width: "100%",
		flexWrap: "wrap",
		columnGap: "35px",
		rowGap: "15px"
	},
	inputWithLabel: {
		display: "flex",
		flexDirection: "column",
		...shorthands.gap("2px"),
		maxWidth: "400px"
	},
	gameItem: {
		display: "flex",
		columnGap: "5px",
		alignItems: "center"
	},
	gameDropdown: {
		display: "flex",
		alignItems: "center",
		columnGap: "6px"
	},
	highlightsContainer: {
		display: "flex",
		rowGap: "3px",
		flexWrap: "wrap",
		columnGap: "5px",
		alignItems: "flex-end"
	},
	highlights: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		columnGap: "3px",
		backgroundColor: tokens.colorBrandBackgroundStatic,
		...shorthands.padding("2px", "10px"),
		...shorthands.borderRadius("5px"),
		width: "200px",
		maxHeight: "40px"
	},
	highlightRating: {
		width: "55px"
	},
	reviewBodyContainer: {
		width: "100%"
	},
	reviewBody: {
		width: "100%",
		height: "250px"
	}
});