import { RouteObject } from "react-router-dom";
import GameDetails from "../Games/GameDetails";
import Games from "../Games/Games";
import Home from "../Home/Home";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Faq from "../Pages/Faq";
import Players from "../Players/Players";
import Profile from "../Profile/Profile";
import Review from "../Reviews/Form/Review";
import ReviewPage from "../Reviews/ReviewPage";
import ReviewSearch from "../Reviews/ReviewSearch";
import ApplicationRoot from "./ApplicationRoot";
import ErrorFallback from "./ErrorFallback";

export const routes: RouteObject[] = [
	{
		path: "/",
		element: <ApplicationRoot />,
		errorElement: <ErrorFallback />,
		children: [
			{
				path: "/",
				element: <Home />
			},
			{
				path: "/games",
				element: <Games />
			},
			{
				path: "/games/:gameTitle",
				element: <GameDetails />
			},
			{
				path: "/players",
				element: <Players />
			},
			{
				path: "/players/:username",
				element: <Profile />
			},
			{
				path: "/reviews",
				element: <ReviewSearch />
			},
			{
				path: "/reviews/:reviewid",
				element: <ReviewPage />
			},
			{
				path: "/reviews/create",
				element: <Review />
			},
			{
				path: "/about",
				element: <About />
			},
			{
				path: "/faq",
				element: <Faq />
			},
			{
				path: "contact",
				element: <Contact />
			}
		]
	}
];