import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./User/userSlice";
import alertSlice from "./Alerts/alertSlice";
import storage from 'redux-persist/lib/storage';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer, persistStore } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { PeerGamingApiSlice } from "./Api/PeerGamingApiSlice";
import { ErrorNotificationMiddleware } from "./Api/ErrorNotifcationMiddleware";

// Local Storage configurations
const persistConfig = {
	key: 'root',
	storage
}

// Uses local storage to persist data between sessions for specific slices of state
const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const pgStore = configureStore({
	reducer: {
		user: persistedUserReducer,
		[alertSlice.name]: alertSlice.reducer,
		[PeerGamingApiSlice.reducerPath]: PeerGamingApiSlice.reducer,
	},
	//Adding the RTK custom generated middleware to the store
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware(
			{
				// Specifically ignoring these as they are throwing an error. getDefaultMiddleware is being deprecated soon anyhow.
				// https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
				serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] }
			})			
			.concat(PeerGamingApiSlice.middleware).concat(ErrorNotificationMiddleware);
	},

});

export const persistor = persistStore(pgStore);

export type AppDispatch = typeof pgStore.dispatch;
export type RootState = ReturnType<typeof pgStore.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;