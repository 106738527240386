import { PeerGamingApiSlice } from "../PeerGamingApiSlice";

export const reviewSlice = PeerGamingApiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			fetchReviews: builder.query<Review[], { reviewId?: number, gameTitle?: string } | void>({
				query: (request) => ({
					url: '/reviews',
					params: {
						id: request?.reviewId,
						videoGameTitle: request?.gameTitle
					}
				}), 
				providesTags: ['Reviews']
			}),

			addReview: builder.mutation<Review, AddReviewRequest>({
				query: (payload) => ({
					url: '/Reviews',
					method: 'POST',
					body: payload
				}),
				invalidatesTags: ['Reviews']
			}),

			fetchHighlights: builder.query<HighlightType[], void>({
				query: () => ({
					url: '/Reviews/Highlights'
				}),
				providesTags: ['Highlights']
			})

		}
	}
});

export const { useFetchReviewsQuery, useLazyFetchReviewsQuery, useAddReviewMutation, useFetchHighlightsQuery } = reviewSlice;

export type ReviewListItem = {
	id: number,
	rating: number,
	title: string,
	recommended: boolean,
	username: string,
	videoGameTitle: string,
	platformName: string
}

export type Review = {
	id: number,
	rating: number,
	title: string, 
	body?: string, 
	recommended: boolean, 
	createDate: string,
	modifiedDate: string,
	username: string,
	videoGameTitle: string,
	platformName: string,
	highlights: Highlight[],
	videoGameBoxArt: string
}

export type Highlight = {
	name: string,
	rating: number
}

export type HighlightType = {
	highlight: string,
	description: string
}

export type AddReviewRequest = {
	id: number,
	rating: number,
	title: string,
	body?: string | undefined,
	recommended?: boolean | undefined,
	playerUsername: string,
	platformName: string,
	videoGameTitle: string,
	highlights?: AddHighlightRequest[] | undefined
}

export type AddHighlightRequest = {
	highlight: string, 
	rating: number
}