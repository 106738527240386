import {
	makeStyles,
	Button,
	shorthands,
	tokens,
	Divider,
	Skeleton,
	SkeletonItem,
	Input,
	Caption1,
} from "@fluentui/react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchReviewsQuery } from "../Api/Reviews/reviewSlice";
import useDebouncer from "../Hooks/useDebouncer";
import { useAppSelector } from "../pgStore";
import { filterByValue } from "../Shared/filterByText";
import ReviewItem from "./ReviewItem";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "18px",
		textAlign: "center"
	},
	cardContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		rowGap: "25px",
		columnGap: "25px",
		justifyContent: "center",
		width: "100%"
	},
	image: {
		backgroundColor: tokens.colorNeutralBackground2,
		width: "100%",
		maxHeight: "200px",
		...shorthands.overflow("hidden")
	},
	skeletonCard: {
		backgroundColor: tokens.colorNeutralBackground1,
		maxWidth: "800px",
		minWidth: "375px",
		height: "312px",
		display: "flex",
		flexDirection: "column",
		rowGap: "8px"
	},
	skeletonCardHeader: {
		display: "flex",
		columnGap: "18px",
		...shorthands.padding("8px")
	},
	skeletonCardFooter: {
		display: "flex",
		justifyContent: "space-between",
		...shorthands.padding("4px", "12px")
	}
});

const ReviewSearch = () => {
	const styles = useStyles();
	const user = useAppSelector((state) => state.user);
	const [searchText, setSearchText] = useState("");
	const [apiSearchText, setApiSearchText] = useState("");
	const { reviews, isLoading } = useFetchReviewsQuery(undefined, {
		selectFromResult: ({ data, isLoading }) => ({
			reviews: filterByValue(data, apiSearchText),
			isLoading
		})
	});
	const navigator = useNavigate();

	useDebouncer(() => {
		setApiSearchText(searchText);
	}, [searchText], 400);

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<h3>Check out what your Peers think of the games they're playing!</h3>
				{
					user &&
					<Button
						type="button"
						appearance="primary"
						onClick={() => navigator("/reviews/create")}
						style={{ height: "35px" }}
					>
						Create
					</Button>
				}
			</div>

			<Divider />

			<div className={styles.root}>

				{isLoading &&
					<>
						<Skeleton style={{ width: "100%" }}>
							<SkeletonItem shape="rectangle" size={36} />
						</Skeleton>
						<div className={styles.cardContainer}>
							{
								Array.from(Array(10)).map((item, index) => (
									<Skeleton key={`review-skeleton-${index}`}>
										<div className={styles.skeletonCard}>
											<div className={styles.skeletonCardHeader}>
												<SkeletonItem shape="circle" size={32} />
												<SkeletonItem shape="rectangle" size={32} />
											</div>
											<SkeletonItem shape="rectangle" size={128} style={{ height: "200px" }} />
											<div className={styles.skeletonCardFooter}>
												<div style={{ width: "32px" }}>
													<SkeletonItem shape="rectangle" size={36} />
												</div>
												<div style={{ width: "96px" }}>
													<SkeletonItem shape="rectangle" size={36} />
												</div>
											</div>
										</div>
									</Skeleton>
								))
							}
						</div>
					</>
				}

				{
					!isLoading &&
					<>
						<Input
							type="text"
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
							placeholder="Search..."
							style={{ width: '100%' }}
							appearance="underline"
						/>

						<div className={styles.cardContainer}>
							{
								reviews?.map((review, index) => (
									<ReviewItem key={review.id} review={review} />
								))
							}
						</div>
					</>
				}

				{
					(reviews?.length ?? 0) < 1 &&
					<Caption1>No Reviews Found!</Caption1>
				}
			</div>

		</>
	);
}

export default ReviewSearch;