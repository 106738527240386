import { apiPlayerDetails, apiPlayerList, apiPlayerGameRequest, apiPlayerDetailsVideoGame, apiPlayerConsoleGamesRequest, consoleList, apiPlayerGameFetchRequest } from "../../Redux/Types";
import { PeerGamingApiSlice } from "../PeerGamingApiSlice";
import { PlayerSocial } from "./types";

export const playersSlice = PeerGamingApiSlice.injectEndpoints({
	endpoints(builder) {
		return {

			fetchPlayers: builder.query<apiPlayerList[], void>({
				query() {
					return '/Players'
				},
				providesTags: (response, error, request) => {
					const players = response?.map(({ username }) => ({ type: 'Player' as const, id: username }));

					return players
						? ['Player', ...players]
						: ['Player']
				},
				transformResponse: (result: apiPlayerList[], meta, arg) => result.sort((left, right) => left.username.localeCompare(right.username))
			}),

			fetchPlayerDetails: builder.query<apiPlayerDetails, string>({
				query: (username) => ({
					url: `/Players/${username}`
				}),
				providesTags: (result, error, username) => {
					return [{ type: 'Player', username }]; // Each combination of username caches to a specific id
				}
			}),

			fetchPlayerGames: builder.query<apiPlayerDetailsVideoGame[], Partial<apiPlayerGameFetchRequest>>({
				query: (payload) => ({
					url: `/Players/${payload.username}/Games`,
					params: {
						platform: payload.name,
						gameTitleContains: payload.gameTitleContains
					}
				}),
				providesTags: (result, error, request) => {
					const tagId = request.username + '-' + request.name;
					return [{ type: 'PlayerGames', id: tagId }];
				}
			}),

			addPlayerGame: builder.mutation<apiPlayerGameRequest, { username: string, body: apiPlayerGameRequest }>({
				query: (payload) => ({
					url: `/Players/${payload.username}/Games`,
					method: 'POST',
					body: payload.body
				}),
				invalidatesTags: (result, error, request) => {
					//Tag represents the users console
					const tagId = request.username + '-' + request.body.name;
					return [{ type: 'PlayerGames', id: tagId }]
				}
			}),

			deletePlayerGame: builder.mutation<void, { username: string, body: apiPlayerGameRequest }>({
				query: (payload) => ({
					url: `/Players/${payload.username}/Games/${payload.body.title}`,
					params: {
						platform: payload.body.name
					},
					method: 'DELETE'
				}),
				invalidatesTags: (result, error, request) => {
					const tagId = request.username + '-' + request.body.name;
					return [{ type: 'PlayerGames', id: tagId }];
				}
			}),

			/** Player Platform endpoints */

			fetchPlayerPlatforms: builder.query<consoleList[], string>({
				query: (username) => ({
					url: `/Players/${username}/Platforms`
				}),
				providesTags: (result, error, username) => {
					return [{ type: 'PlayerPlatforms', id: username }];
				},
				transformResponse: (result: consoleList[]) => result.sort((left, right) => left.name.localeCompare(right.name))
			}),

			addPlayerPlatform: builder.mutation<boolean, { username: string, body: apiPlayerConsoleGamesRequest }>({
				query: (payload) => ({
					url: `/Players/${payload.username}/Platforms`,
					method: 'POST',
					body: payload.body
				}),
				invalidatesTags: (result, error, playerPlatform) => {
					return [{ type: 'PlayerPlatforms', id: playerPlatform.username }];
				}
			}),

			deletePlayerPlatform: builder.mutation<boolean, { username: string, body: apiPlayerConsoleGamesRequest }>({
				query: (payload) => ({
					url: `/Players/${payload.username}/Platforms/${payload.body.name}`,
					method: 'DELETE'
				}),
				invalidatesTags: (result, error, playerPlatform) => {
					return [{ type: 'PlayerPlatforms', id: playerPlatform.username }];
				}
			}),

			fetchPlayerSocials: builder.query<PlayerSocial[], string>({
				query: (username) => ({
					url: `/Players/${username}/socials`
				}),
				providesTags: (result, error, username) => [{ type: 'PlayerSocials', username }]
			}),

			addPlayerSocial: builder.mutation<PlayerSocial, { id: number, socialUsername: string, username: string }>({
				query: (request) => ({
					url: `/Players/${request.username}/socials`,
					method: 'Post',
					body: {
						id: request.id,
						socialUsername: request.socialUsername
					}
				}),
				invalidatesTags: (result, error, username) => [{ type: 'PlayerSocials', username }]
			}),

			deletePlayerSocial: builder.mutation<void, { id: number, username: string }>({
				query: (request) => ({
					url: `/Players/${request.username}/socials/${request.id}`,
					method: 'DELETE'
				}),
				invalidatesTags: (result, error, username) => [{ type: 'PlayerSocials', username }]
			})
		}
	}
});

export const { useFetchPlayerDetailsQuery, useFetchPlayersQuery,
	useAddPlayerGameMutation, useFetchPlayerGamesQuery, useDeletePlayerGameMutation,
	useFetchPlayerPlatformsQuery, useAddPlayerPlatformMutation, useDeletePlayerPlatformMutation,
	useFetchPlayerSocialsQuery, useAddPlayerSocialMutation, useDeletePlayerSocialMutation } = playersSlice;