import { VideoGameListItem } from "../../Api/Games/types";
import { consoleList } from "../../Redux/Types";
import { NewReviewFormState, NewReviewFormReducerAction, NewReviewFormReducerActionTypes, ReviewHighlight } from "../Types"

export const newReviewFormInitialState = {
	id: 0,
	game: null,
	platform: null,
	title: '',
	details: '',
	rating: 5,
	recommended: false,
	highlights: []
} as NewReviewFormState;

export function newReviewFormReducer(state: NewReviewFormState, action: NewReviewFormReducerAction) {
	switch (action.type) {
		case NewReviewFormReducerActionTypes.reset:
			return {
				...newReviewFormInitialState
			}

		case NewReviewFormReducerActionTypes.bind:
			return {
				id: action.payload.id,
				game: { title: action.payload.videoGameTitle, boxArtUrl: action.payload.videoGameBoxArt } as VideoGameListItem,
				platform: { name: action.payload.platformName } as consoleList,
				title: action.payload.title,
				details: action.payload.body ?? "",
				rating: action.payload.rating,
				recommended: action.payload.recommended,
				highlights: action.payload.highlights?.map((item) => ({ type: item.name, rating: item.rating } as ReviewHighlight))
			} as NewReviewFormState

		case NewReviewFormReducerActionTypes.setGame:
			return {
				...state,
				game: action.payload,
				platform: action.payload.availableOnConsoles.length === 1 ? action.payload.availableOnConsoles[0] : null
			}

		case NewReviewFormReducerActionTypes.setPlatform:
			return {
				...state,
				platform: action.payload
			}

		case NewReviewFormReducerActionTypes.setTitle:
			return {
				...state,
				title: action.payload
			}

		case NewReviewFormReducerActionTypes.setDetails:
			return {
				...state,
				details: action.payload
			}

		case NewReviewFormReducerActionTypes.setRating:
			return {
				...state,
				rating: action.payload
			}

		case NewReviewFormReducerActionTypes.setRecommendation:
			return {
				...state,
				recommended: action.payload
			}

		case NewReviewFormReducerActionTypes.addHighlight:

			if (state.highlights.filter((highlight) => highlight.type === action.payload.type).length > 0) {
				return { ...state }
			}

			return {
				...state,
				highlights: [...state.highlights, action.payload]
			}

		case NewReviewFormReducerActionTypes.updateHighlight:
			const highlightsCopy = [...state.highlights];
			const targetHighlight = highlightsCopy[action.payload.index];
			if (action.payload.rating) {
				targetHighlight.rating = action.payload.rating;
			}
			return {
				...state,
				highlights: highlightsCopy
			}

		case NewReviewFormReducerActionTypes.removeHighlight:
			const arrayCopy = [...state.highlights];
			arrayCopy.splice(action.payload.index, 1);
			return {
				...state,
				highlights: arrayCopy
			}

		default:
			return { ...state }
	}
}