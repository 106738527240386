import {
	makeStyles,
	Caption1,
	Button,
	shorthands,
	Card,
	CardFooter,
	CardHeader,
	CardPreview,
	Persona,
	tokens,
	Link,
	Caption1Stronger,
} from "@fluentui/react-components";
import { ThumbLike16Filled } from "@fluentui/react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Platform } from "../Media/Types";
import { Review } from "../Api/Reviews/reviewSlice";
import BoxArt from "../Shared/BoxArt";

const useStyles = makeStyles({
	root: {
		maxWidth: "800px",
		minWidth: "375px",
		width: "100%",
		flexBasis: "45%",
	},
	platformIcon: {
		backgroundColor: tokens.colorNeutralForeground1,
		width: "35px",
		...shorthands.borderRadius("12px"),
		...shorthands.border("1px", "solid", tokens.colorNeutralBackground1),
		textAlign: "center"
	},
	link: {
		color: tokens.colorBrandForeground1,
		":hover": {
			color: tokens.colorBrandForeground2Hover
		}
	}
});

export type ReviewItemProps = {
	review: Review
}

const ReviewItem = (props: ReviewItemProps) => {
	const styles = useStyles();
	const platformObj = new Platform(props.review.platformName);
	const navigator = useNavigate();

	return (
		<Card
			className={styles.root}
		>
			<CardHeader
				image={
					<>
						<Persona
							name={props.review.username}
							primaryText=""
							avatar={{ color: "brand" }}
						/>
					</>
				}
				header={
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "30px" }}>

						<div style={{ display: "flex", columnGap: "3px", alignItems: "center" }}>
							<Link appearance="subtle" style={{ textDecoration: "none" }} className={styles.link} href={`/players/${props.review.username}`}>
								<Caption1Stronger>{props.review.username}</Caption1Stronger>
							</Link>
							<Caption1>reviewed</Caption1>
							<Link appearance="subtle" style={{ textDecoration: "none" }} className={styles.link} href={`/games/${props.review.videoGameTitle}`}>
								<Caption1Stronger>{props.review.videoGameTitle}</Caption1Stronger>
							</Link>
						</div>

						<div className={styles.platformIcon}>
							<b style={{ color: tokens.colorBrandForeground1 }}>{props.review.rating}</b>
						</div>
					</div>
				}
			>
			</CardHeader>

			<CardPreview
				logo={
					<div className={styles.platformIcon}>
						<FontAwesomeIcon
							icon={platformObj.FaIconName}
							title={props.review.platformName}
							style={{ color: tokens.colorNeutralBackground1 }} />
					</div>
				}
			>
				<BoxArt
					imageSrc={props.review.videoGameBoxArt}
					altText={`${props.review.videoGameTitle}`}
					splash
					containerOptions={{
						width: "100%"
					}}
				/>

			</CardPreview>

			<CardFooter
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<Button
					icon={
						<ThumbLike16Filled />
					}
					title="Coming soon"
					disabled
				></Button>
				<Button
					onClick={(event) => navigator(`/Reviews/${props.review.id}`)}
				>
					View
				</Button>
			</CardFooter>
		</Card>
	);
}

export default ReviewItem;