import { Theme, webDarkTheme } from "@fluentui/react-components";

/** Extension of the Fluent UI theme to add custom tokens used throughout the program.  */
type PeerGamingCustomThemeTokens = {
	customSpacingVerticalHuge: string
}

/**
 * Customized Theme for the Fluent Provider
 */
export const PeerGamingDarkTheme: Theme & PeerGamingCustomThemeTokens = {
	...webDarkTheme,
	customSpacingVerticalHuge: '128px'
};

