import { Image, makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
	root: {
		backgroundColor: tokens.colorNeutralBackground2,
		...shorthands.overflow("hidden"),
		position: "relative"
	},
	thumbnail: {
		position: "absolute",
		...shorthands.margin("auto"),
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		textAlign: "center",
		height: "100%",
		width: "100%",
		objectFit: "cover",
		...shorthands.overflow("hidden")
	},
	image: {
		position: "absolute",
		...shorthands.margin("auto"),
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		textAlign: "center",
		maxHeight: "100%",
		maxWidth: "100%",
		objectFit: "cover",
		...shorthands.overflow("hidden")
	},
	splash: {
		width: "100%",
		opacity: "0.3",
		position: "absolute",
		objectFit: "contain",
		top: "-100px",
		left: 0,
		right: 0,

	}
});

export type BoxArtProps = {
	/** Image to render */
	imageSrc: any,
	/** Set to true for smaller images, and false for larger images. Defaults to false. */
	thumbnail?: boolean,
	/** Image Alt Text */
	altText: string,
	fit?: boolean,
	/** When true, uses the same image passed in as the background for the container */
	splash?: boolean,
	rounded?: boolean,
	containerOptions?: {
		minHeight?: string,
		height?: string,
		maxHeight?: string,
		minWidth?: string,
		width?: string,
		maxWidth?: string
	},
	imageOptions?: {
		minHeight?: string,
		height?: string,
		maxHeight?: string,
		minWidth?: string,
		width?: string,
		maxWidth?: string
	}
}

const BoxArt = (props: BoxArtProps) => {
	const styles = useStyles();

	//useEffect(() => {
	//	if (props.splash) {
	//		try {
	//			var bytechars = atob(props.imageSrc.replace("data:image/jpeg;base64,", ""));
	//			var byteNumbers = new Array(bytechars.length);
	//			for (var i = 0; i < bytechars.length; i++) {
	//				byteNumbers[i] = bytechars.charCodeAt(i);
	//			}
	//			var byteArray = new Uint8Array(byteNumbers);
	//			var blob = new Blob([byteArray], { type: 'application/x-pkcs12' });

	//			setBackgroundImageUrl((window.URL || window.webkitURL).createObjectURL(blob));
	//		} catch {
	//			console.log("Could not generate background image.")
	//		}
	//	}
	//}, []);

	return (
		<div
			className={styles.root}
			style={{
				minHeight: props.containerOptions?.minHeight,
				height: props.containerOptions?.height ?? "200px",
				maxHeight: props.containerOptions?.maxHeight,
				minWidth: props.containerOptions?.minWidth,
				width: props.containerOptions?.width,
				maxWidth: props.containerOptions?.maxWidth,
				borderBottomLeftRadius: props.rounded ? "5px" : "",
				borderBottomRightRadius: props.rounded ? "5px" : "",
				borderTopLeftRadius: props.rounded ? "5px" : "",
				borderTopRightRadius: props.rounded ? "5px" : "",
			}}

		>
			{
				props.splash &&
				<Image
					id="splash-image"
					src={props.imageSrc}
					alt={`${props.altText} Splash Image`}
					className={styles.splash}
					style={{
						minHeight: "400px"
					}}
				/>
			}

			<Image
				src={props.imageSrc}
				alt={props.altText}
				className={props.thumbnail ? styles.thumbnail : styles.image}
				style={{
					minHeight: props.imageOptions?.minHeight,
					height: props.imageOptions?.height,
					maxHeight: props.imageOptions?.maxHeight,
					minWidth: props.imageOptions?.minWidth,
					width: props.imageOptions?.width,
					maxWidth: props.imageOptions?.maxWidth
				}}
			/>
		</div>
	)
}

export default BoxArt;