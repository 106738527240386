import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistor, pgStore } from '../pgStore'
import { PersistGate } from 'redux-persist/integration/react';
import { FluentProvider } from '@fluentui/react-components';
import { PeerGamingDarkTheme } from '../Theme/PeerGamingDarkTheme';
import Application from './Application';


const ApplicationInitializer = () => {

	useEffect(() => {
		const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
		window.addEventListener('resize', appHeight)
		appHeight()
	}, []);

	return (
		<React.StrictMode>
			<Provider store={pgStore}>
				<PersistGate loading={null} persistor={persistor}>
					<FluentProvider theme={PeerGamingDarkTheme}>
						<Application />
					</FluentProvider>
				</PersistGate>
			</Provider>
		</React.StrictMode>
	)
}

export default ApplicationInitializer;