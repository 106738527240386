import { ProfileProps } from '../Profile/Types';
import { useParams } from 'react-router-dom';
import { useFetchPlayerDetailsQuery } from '../Api/Players/playersSlice';
import ProfileContent from '../Profile/ProfileContent';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../pgStore';
import NotFound from '../Shared/NotFound';
import { Spinner } from '@fluentui/react-components';

import '../Profile/Profile.scss'

const Profile = (props: ProfileProps) => {

	const currentUser = useAppSelector((state) => state.user);

	const { username } = useParams();

	// UseParams returns a type string | undefined which cannot be passed into userFetchPlayerQuery, casting as String() 
	// username cannot be empty or undefined because /Players/ would lead to the Players page
	const { data, isLoading, isError } = useFetchPlayerDetailsQuery(String(username));

	const [isEditor, setIsEditor] = useState(false);

	useEffect(function setEditor() {
		if (data?.username && data.username === currentUser.username) {
			setIsEditor(true);
		} else {
			setIsEditor(false);
		}
	}, [data, currentUser]);

	return (
		<>
			{isLoading && !isError && <Spinner style={{paddingTop: "125px"}} size="extra-large" />}
			{!isLoading && !isError && data && <ProfileContent player={data} isEditable={isEditor} />}
			{!isLoading && !isError && !data && <NotFound />}
		</>

	);
}

export default Profile;