import { loginRequest, apiPlayerDetails } from "../../Redux/Types";
import { PeerGamingApiSlice } from "../PeerGamingApiSlice";

export const userSlice = PeerGamingApiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			login: builder.mutation<apiPlayerDetails, Partial<loginRequest>>({
				query: (body) => ({
					url: `/Users/Login`,
					method: 'POST',
					body
				})
			}),

			createLogin: builder.mutation<apiPlayerDetails, apiPlayerDetails>({
				query: (body) => ({
					url: '/Users',
					method: 'POST',
					body
				})
			})

		}
	}
});

export const { useLoginMutation, useCreateLoginMutation } = userSlice;