import { Body1, Body1Strong, Button, Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { MouseEvent, useState } from "react";
import { useDeletePlayerSocialMutation, useFetchPlayerSocialsQuery } from "../Api/Players/playersSlice";
import { PlayerSocial } from "../Api/Players/types";
import { useAppSelector } from "../pgStore";

const useStyles = makeStyles({
	container: {
		display: "flex",
		columnGap: "2px",
		flexWrap: "wrap"
	},
	tags: {
		display: "flex",
		justifyContent: "left",
		columnGap: "15px"
	},
	tag: {
		display: "flex",
		justifyContent: "space-between",
		columnGap: "3px",
		width: "182px",
		...shorthands.border("1px", "solid", tokens.colorBrandBackground2),
		...shorthands.borderRadius("3px"),
		backgroundColor: tokens.colorBrandBackgroundStatic,
		position: "relative",
		overflowX: "hidden",
		overflowY: "hidden"
	},
	tagUsername: {
		backgroundColor: tokens.colorBrandBackground2,
		width: "120px",
		justifyContent: "right",
		textAlign: "center"
	},
	showRemoveBtn: {
		backgroundColor: "red",
		height: "100%",
		width: "24px",
		position: "absolute",
		right: 0,
		borderTopRightRadius: "3px",
		borderBottomRightRadius: "3px",
		transitionDuration: "1s",
		visibility: "visible"
	},
	hideRemoveBtn: {
		backgroundColor: "red",
		height: "100%",
		width: "24px",
		position: "absolute",
		right: "-24px",
		borderTopRightRadius: "3px",
		borderBottomRightRadius: "3px",
		transitionDuration: "1s",
		visibility: "hidden"
	}
});

export type PlayerSocialsProps = {
	profileUsername: string,
	isEditable: boolean
}

const PlayerSocials = (props: PlayerSocialsProps) => {
	const styles = useStyles();
	const { data } = useFetchPlayerSocialsQuery(props.profileUsername)

	return (
		<div className={styles.container}>
			{
				data && data.map((item, index) =>
					<SocialCard key={`${item.playerSocialUsername}`} item={item} isEditable={props.isEditable} />
				)
			}
		</div>
	)
}

const SocialCard = (props: { item: PlayerSocial, isEditable: boolean }) => {
	const styles = useStyles();
	const user = useAppSelector((state) => state.user.username);
	const [showRemove, setShowRemove] = useState(false);
	const [removeSocial] = useDeletePlayerSocialMutation();

	const onRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
		if (user) {
			removeSocial({ id: Number(props.item.playerSocialPlatformID), username: user });
		}
	}

	return (
		<div className={styles.tag} onMouseEnter={() => setShowRemove(true)} onMouseLeave={() => setShowRemove(false)}>
			<Body1 style={{ margin: "auto" }} title={props.item.socialPlatformName}>
				{props.item.abbreviatedName ?? props.item.socialPlatformName}
			</Body1>

			<div className={styles.tagUsername}>
				{
					props.item.websiteTemplate
						?
						<Link href={props.item.websiteTemplate} target="_blank">
							<Body1Strong>{props.item.playerSocialUsername}</Body1Strong>
						</Link>
						:
						<Body1Strong>{props.item.playerSocialUsername}</Body1Strong>
				}

			</div>

			{
				props.isEditable &&
				<div className={showRemove ? styles.showRemoveBtn : styles.hideRemoveBtn}>
					<Button
						appearance="transparent"
						icon={
							<Dismiss24Regular />
						}
						style={{ height: "18px", marginBottom: "2px", paddingRight: "12px" }}
						onClick={onRemoveClick}
					>

					</Button>
				</div>
			}
		</div>
	);
}

export default PlayerSocials;