import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { SidebarProps } from "./Types";
import { useAppSelector } from "../pgStore";
import UnauthenticatedUser from "./User/UnauthenticatedUser";
import AuthenticatedUser from "./User/AuthenticatedUser";
import { Button, Divider, Image, makeStyles, shorthands } from "@fluentui/react-components";
import { MoreHorizontal32Filled } from "@fluentui/react-icons";
import { Nav, createTheme, ITheme } from "@fluentui/react";
import NavbarLinks from "./NavbarLinks";

import './layoutStyles.scss'

const myTheme: ITheme = createTheme({
	palette: {
		// Left selected bar color
		themePrimary: "#07E3E3",
		// Unselected Text Color
		neutralPrimary: "#fefefe",
		// Hover color
		neutralLighter: "#071919",
		// Selected Text Color
		black: "#fefefe",
		// Selected Background Color
		neutralLight: "#43575F",

		// Nothing
		themeLight: "orange",
		themeLighter: "red",
		themeSecondary: "purple",
		neutralSecondary: "green",
		neutralPrimaryAlt: "yellow",
		neutralSecondaryAlt: "darkgreen",
		accent: "orange",
		neutralDark: "yellow",
		neutralTertiary: "purple",
		neutralLighterAlt: "purple",
		themeDark: "yellow",
		themeDarkAlt: "orange",
		themeDarker: "green",
		themeLighterAlt: "green",
		neutralTertiaryAlt: "yellow"
	},
	defaultFontStyle: {
		fontFamily: "Press Start 2P, sans serif"
	}
});

const useStyles = makeStyles({
	loginForm: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		width: "100%",
		...shorthands.margin("auto"),
		...shorthands.padding("5px")
	}
});

const SideBar = (props: SidebarProps) => {
	const styles = useStyles();
	const currentUser = useAppSelector((state) => state.user);
	const navigator = useNavigate();

	function navItemClick(event: any, item: any) {
		event.preventDefault();
		navigator(item.url);
		props.toggle(false);
	}

	return (
		<div id="application-navbar" className={props.isOpen ? "" : `unfocused`} >

			<div id="application-menu">
				<div style={{ backgroundColor: "#031024" }}>
					<img className={classNames("logo")} src="/Peer-Gaming-Logo-256.png" alt="" />
				</div>

				<Divider />

				<div>
					<Nav
						groups={NavbarLinks}
						onLinkClick={navItemClick}
						theme={myTheme}
					/>
				</div>

				<div className="application-sidebar-footer">
					<p className="copyright">&copy; Sam Travarca</p>
					<Divider />

					<div className={styles.loginForm}>
						{
							!currentUser.authenticated &&
							<UnauthenticatedUser />
						}

						{
							currentUser.authenticated &&
							<AuthenticatedUser />
						}

					</div>
				</div>
			</div>

			<div id="application-mobile-navbar">
				<Image
					src="Peer-Gaming-Logo-Minimized-Mobile.png"
					onClick={(event) => navigator("/")}
				/>
				<span className="mobile-nav-title">
					Peer Gaming
				</span>
				<Button
					appearance="subtle"
					icon={
						<MoreHorizontal32Filled />
					}
					onClick={(event) => props.toggle(true)}
				>
				</Button>
			</div>
		</div>
	);
}

export default SideBar;
