import { makeStyles, Persona, shorthands, Subtitle2 } from "@fluentui/react-components";
import { useFetchReviewsQuery } from "../Api/Reviews/reviewSlice"
import { useNavigate } from 'react-router-dom';

export type ReviewToolstripProps = {
	title: string,
	gameTitle?: string
}

/** Displays a toolstrip of related reviews based on a Game or Player passd in. */
export default function ReviewToolstrip(props: ReviewToolstripProps) {
	const styles = useStyles();
	const navigator = useNavigate();
	const { data: reviews } = useFetchReviewsQuery({ gameTitle: props.gameTitle });

	const itemClick = (id: number) => {
		if (id) {
			navigator(`/reviews/${id}`);
		}
	}

	return (
		<div className={styles.root}>
			<Subtitle2>{props.title}</Subtitle2>
			<div>
				{
					reviews &&
					reviews.map((item, index) =>
						<Persona
							key={`review-link-${index}`}
							size="large"
							name={item.username}
							title={item.username}
							primaryText=""
							avatar={{ color: "colorful" }}
							onClick={() => itemClick(item.id)}
							style={{ cursor: "pointer" }}
						/>
					)
				}
			</div>
		</div>
	)
}

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "2px",
		width: "100%",
		flexWrap: "nowrap",
		...shorthands.overflow("hidden")
	},
	container: {
		display: "flex"
	}
});