import {
	Caption2, Combobox, ComboboxProps, Label, makeStyles, Option, shorthands, tokens, useId
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useFetchGamesQuery } from "../../Api/Games/gamesSlice";
import { NewReviewFormReducerAction, NewReviewFormReducerActionTypes, NewReviewFormState } from "../Types";
import BoxArt from "../../Shared/BoxArt";

export type NewReviewProps = {
	formContext: NewReviewFormState,
	formDispatch: React.Dispatch<NewReviewFormReducerAction>,
	gameTitle?: string,
	platformName?: string
}

const NewReview = (props: NewReviewProps) => {
	const styles = useStyles();
	const id = useId("review");

	const [comboValue, setComboValue] = useState<string>("");
	const { data: games } = useFetchGamesQuery({ gameTitleStartsWith: comboValue });

	useEffect(() => {
		// Set default values when supplied in query string 
		const queryParamGame = games?.find((x) => x.title === props.gameTitle);
		if (queryParamGame) {
			props.formDispatch({
				type: NewReviewFormReducerActionTypes.setGame,
				payload: queryParamGame
			});

			// If there is only one platform it was already automagically set, otherwise see if we were given a valid platform to set
			if (queryParamGame.availableOnConsoles.length > 1) {
				const platform = queryParamGame.availableOnConsoles.find((p) => p.name === props.platformName);
				if (platform) {
					props.formDispatch({
						type: NewReviewFormReducerActionTypes.setPlatform,
						payload: platform
					})
				}
			}
		}

	}, [games, props]);

	const handleFilterChange: ComboboxProps["onChange"] = (event) => {
		const value = event.target.value;
		setComboValue(value);
	}

	return (
		<div className={styles.form}>
			<div className={styles.gameDropdown}>
				<div className={styles.inputWithLabel}>
					<Label htmlFor={`${id}-game-combobox`} style={{ textAlign: "left" }}>Select a Game</Label>
					<Combobox
						id={`${id}-game-combobox`}
						placeholder="Select a Game to begin"
						selectedOptions={[""]}
						value={comboValue}
						onChange={handleFilterChange}
						onOptionSelect={(event, data) => {
							const selectedGame = games?.find((x) => x.title === data.optionValue);
							if (selectedGame) {
								props.formDispatch({ type: NewReviewFormReducerActionTypes.setGame, payload: selectedGame });
							}
						}}
					>
						{
							games?.map((game, index) =>
								<Option key={index} text={game.title} value={game.title}>
									<div className={styles.gameItem}>
										{
											game.boxArtUrl &&
											<BoxArt
												imageSrc={game.boxArtUrl}
												altText=""
												containerOptions={{
													height: "25px",
													width: "25px"
												}}
												imageOptions={{
													minHeight: "100%"
												}}
											/>
										}
										<Caption2>{game.title}</Caption2>
									</div>
								</Option>
							)
						}
						{
							!games || games?.length === 0 ? (
								<Option key="no-results" text="">
									No Results Found
								</Option>
							) : null
						}
					</Combobox>
				</div>
			</div>
		</div>

	);
};

export default NewReview;

const useStyles = makeStyles({
	root: {

	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		rowGap: "25px"
	},
	formHeader: {
		display: "flex",
		columnGap: "35px",
		width: "100%"
	},
	formSection: {
		display: "flex",
		width: "100%",
		flexWrap: "wrap",
		columnGap: "35px",
		rowGap: "15px"
	},
	inputWithLabel: {
		display: "flex",
		flexDirection: "column",
		...shorthands.gap("2px"),
		maxWidth: "400px"
	},
	gameItem: {
		display: "flex",
		columnGap: "5px",
		alignItems: "center"
	},
	gameDropdown: {
		display: "flex",
		alignItems: "center",
		columnGap: "6px"
	},
	highlightsContainer: {
		display: "flex",
		rowGap: "3px",
		flexWrap: "wrap",
		columnGap: "5px",
		alignItems: "flex-end"
	},
	highlights: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		columnGap: "3px",
		backgroundColor: tokens.colorBrandBackgroundStatic,
		...shorthands.padding("2px", "10px"),
		...shorthands.borderRadius("5px"),
		width: "200px",
		maxHeight: "40px"
	},
	highlightRating: {
		width: "55px"
	},
	reviewBodyContainer: {
		width: "100%"
	},
	reviewBody: {
		width: "100%",
		height: "250px"
	}
});