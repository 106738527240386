import { PgFormField } from "../Shared/Types"

export type newUserFormState = {
	username: PgFormField<string | undefined>,
	emailAddress: PgFormField<string | undefined>,
	password: PgFormField<string | undefined>,
	firstName?: string,
	middleInitial?: string,
	lastName?: string,
	birthday?: string
}

export type newUserFormReducerAction = {
	type: newUserFormReducerActionTypes,
	payload: any
}

export enum newUserFormReducerActionTypes {
	setInputData,
	setInputValidation
}