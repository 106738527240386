import { Body2, makeStyles, shorthands, Title3, tokens } from "@fluentui/react-components";
import { Review } from "../Api/Reviews/reviewSlice";

const useStyles = makeStyles({
	root: {
		backgroundColor: tokens.colorNeutralBackground3,
		display: "flex",
		flexDirection: "column",
		rowGap: "15px",
		...shorthands.padding("15px")
	},
	body: {
		whiteSpace: "pre-wrap"
	}
});

const ReviewBody = (props: { review: Review }) => {
	const styles = useStyles();

	return (
		<div className={styles.root} style={{}}>
			<Title3>{props.review.title}</Title3>
			<Body2 className={styles.body} as="p" block>{props.review.body}</Body2>
		</div>
	)
};

export default ReviewBody;
