import classNames from 'classnames';
import { apiPlayerDetails } from '../Redux/Types';
import { makeStyles, Subtitle1, Image } from "@fluentui/react-components";
import PlayerHeader from './PlayerHeader';
import PlayerSocials from './PlayerSocials';
import RecentActivity from './RecentActivity';
import logo from "../Assets/cool_space.jpg";
import PlayerPlatforms from './PlayerPlatforms';
import ProfileSection from './ProfileSection';

const useStyles = makeStyles({
	root: {
		marginTop: "220px",
		zIndex: 1500,
		position: "inherit"
	},
	profileImage: {
		width: "100%",
		maxWidth: "1200px",
		position: "absolute",
		maxHeight: "320px",
		left: 0,
		top: 0,
		right: 0,
		marginLeft: "auto",
		marginRight: "auto",
		zIndex: 10,
		objectFit: "contain"
	}
});

export type ProfileContentProps = {
	player: apiPlayerDetails,
	isEditable: boolean
}

/** Profile Container holding all of the components to build the profile page. */
const ProfileContent = (props: ProfileContentProps) => {
	const styles = useStyles();
	console.log("profile content")

	return (
		<>
			<Image
				className={styles.profileImage}
				src={logo}
				alt="Profile Alt Text" />

			<div className={styles.root}>
				<PlayerHeader username={props.player.username} isEditable={props.isEditable} />

				<PlayerSocials profileUsername={props.player.username} isEditable={props.isEditable} />

				<div className={classNames("profile-body")}>

					<ProfileSection title="Recent Activity" content={<RecentActivity />} />

					<ProfileSection title="Library" content={<PlayerPlatforms username={props.player.username} isEditable={props.isEditable} />} />

					<ProfileSection title="Followers" content={<Subtitle1>Coming Soon...</Subtitle1>} />

				</div>

			</div>
		</>

	);
}

export default ProfileContent;