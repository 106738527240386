export type PgFormField<T> = {
	value?: T,
	validationState?: 'error' | 'warning' | 'success' | 'none',
	validationMessage?: string,
	visited?: boolean
}

export enum InfoCardPostType {
    NewRelease = "New Release",
    Update = "Update",
	Upcoming = "Upcoming",
	Hot = "Hot"
}

export type InfoCardProps = {
    types?: InfoCardPostType[],
    title: string,
    subtitle?: string,
    body?: string,
    imageSource?: string 
}

