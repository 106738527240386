import {
	Caption1,
	Divider,
	Input,
	makeStyles,
	Skeleton,
	SkeletonItem,
	tokens
} from "@fluentui/react-components";
import { MouseEvent, useState } from "react";
import { useFetchGamesQuery } from "../Api/Games/gamesSlice";
import useDebouncer from "../Hooks/useDebouncer";
import { useAppSelector } from "../pgStore";
import GameFormModal from "./Form/GameFormModal";
import GameItem from "./GameItem";

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '18px'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: '8px',
		rowGap: '8px',
		justifyContent: 'center'
	},
	skeletonCard: {
		backgroundColor: tokens.colorNeutralBackground2,
		width: "250px",
		height: "288px",
		display: "flex",
		flexDirection: "column",
		rowGap: "4px"
	}
});

const Games = () => {
	const styles = useStyles();
	const [searchText, setSearchText] = useState("");
	const [apiSearchText, setApiSearchText] = useState("");

	const { data: games, isLoading } = useFetchGamesQuery({gameTitleStartsWith: apiSearchText});

	useDebouncer(() => {
		setApiSearchText(searchText);
	}, [searchText], 400);

	const [showAdd, setShowAdd] = useState(false);
	const currentUser = useAppSelector((state) => state.user.username);
	const adminAddGame = (event: MouseEvent<HTMLHeadingElement>) => {
		if (currentUser === "SneakySquid") {
			setShowAdd(true);
		}
	}

	return (
		<>
			<h3 onClick={adminAddGame}>Find A Game</h3>
			<Divider />

			<div className={styles.root}>
				{
					isLoading &&
					<>
						<Skeleton style={{ width: "100%" }}>
							<SkeletonItem shape="rectangle" size={36} />
						</Skeleton>

						<div className={styles.container}>
							{
								Array.from(Array(20)).map((item, index) => {
									return (
										<Skeleton key={index}>
											<div className={styles.skeletonCard}>
												<SkeletonItem shape="rectangle" size={40} />
												<SkeletonItem shape="rectangle" size={128} style={{ height: "200px" }} />
												<SkeletonItem shape="rectangle" size={40} />
											</div>
										</Skeleton>
									)
								})
							}
						</div>
					</>
				}

				{
					!isLoading &&
					<>
						<Input
							type="text"
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
							placeholder="Search..."
							style={{ width: '100%' }}
							appearance="underline"
						/>
						<div className={styles.container}>
							{
								games?.map((game, index) =>
									<GameItem key={`game-${index}`} game={game} />
								)
							}
						</div>
						{
							(games?.length ?? 0) < 1 &&
							<Caption1>No Games Found!</Caption1>
						}
					</>

				}

				{
					showAdd &&
					<GameFormModal
						showModal={showAdd}
						setShowModal={setShowAdd}
					/>
				}
			</div>
		</>
	);
}

export default Games;