import { isRejected, isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { sendNotification } from "../Alerts/alertSlice";
import { NotificationProps } from "../Layout/Alerts/Notification";

const excludedEndpoints: string[] = ["login"];

export const ErrorNotificationMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {	

	if (action.meta?.arg?.endpointName && excludedEndpoints.includes(action.meta.arg.endpointName)) {
		return next(action);
	}

	if (isRejectedWithValue(action)) {
		console.log(action);
		const error: Omit<NotificationProps, "uniqueId"> = {
			intent: "error",
			appearance: "inverted",
			message: isBadRequest(action) ? extractBadRequestMessage(action) : `An error occurred with the ${action.meta.arg.endpointName} API.` //action.error.data.message
		}
		api.dispatch(sendNotification(error));
	} else if (isRejected(action)) {
		console.log(action);
		api.dispatch(sendNotification({
			appearance: "inverted",
			intent: "error",
			message: isBadRequest(action) ? extractBadRequestMessage(action) : `An error occurred with the ${action.meta.arg.endpointName} API.`
		}));
	}

	return next(action);
}

function isBadRequest(action: any) {
	console.log(action?.payload?.data?.status)
	if (action?.payload?.data?.status === 400) {
		return true;
	}
	return false;
}

function extractBadRequestMessage(action: any) {
	const errors = action?.payload?.data?.errors;
	const errorMessages: string[] = [];

	for (var prop in errors) {
		if (Object.prototype.hasOwnProperty.call(errors, prop)) {
			errorMessages.push(errors[prop]);
		}
	}

	return errorMessages.join();
}