import {
	Input, Button, useId, Field, makeStyles, Spinner
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../pgStore';
import { sendNotification } from '../../Alerts/alertSlice';
import { apiPlayerDetails, apiErrorResponse, loginRequest } from '../../Redux/Types';
import { login } from '../../User/userSlice';
import NewUser from '../../SignUp/NewUser';
import { useLoginMutation } from '../../Api/User/userSlice';
import { PgFormField } from '../../Shared/Types';

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignContent: "center",
		columnGap: "3px",
		rowGap: "5px",
		width: "100%"
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "space-around",
		width: "100%"
	}
});

const UnauthenticatedUser = () => {
	const id = useId("sideBarLogin");
	const styles = useStyles();
	const dispatch = useAppDispatch();

	const [username, setUsername] = useState<PgFormField<string> | undefined>();
	const [password, setPassword] = useState<PgFormField<string> | undefined>();
	const [showSignUpModal, setSignUpModal] = useState<boolean>(false);
	const toggleSignUpModal = () => setSignUpModal(!showSignUpModal);
	const [showLogin, setShowLogin] = useState<boolean>(true);
	const toggleLogin = () => setShowLogin(!showLogin);

	const [attemptLogin, { isLoading, data, isSuccess, isError, error }] = useLoginMutation();

	function loginUser() {

		if (!username?.value) {
			setUsername({
				...username,
				validationState: "error",
				validationMessage: "Username is required to login"
			} as PgFormField<string>);
		}

		if (!password?.value) {
			setPassword({
				...password,
				validationState: "error",
				validationMessage: "Password is required to login"
			} as PgFormField<string>);
		}

		if (!isLoading && username?.value && password?.value) {
			attemptLogin({ Username: username.value, Password: password.value } as loginRequest);
		}
	}

	useEffect(function unsuccessfulLogin() {
		if (isError) {
			let errRaw = JSON.stringify(error);
			let errJson: apiErrorResponse = JSON.parse(errRaw);
			let errText: string = "";

			if (errJson.status === 404) {
				errText = "Sorry, we don't know anyone by that combination.";
			} else {
				const errorMessage = [] as string[];
				errJson.data?.errors?.forEach((err, index) => {
					errorMessage.push(err.name || "We're sorry, something went wrong. \r\n Try Again.");
				});
				errText = errorMessage.join("\r\n");
			}

			dispatch(sendNotification({
				appearance: "inverted",
				intent: "error",
				message: errText
			}));
		}
	}, [isError, error, dispatch]);

	useEffect(function successfulLogin() {
		if (isSuccess) {
			const user: apiPlayerDetails = JSON.parse(JSON.stringify(data));
			dispatch(login(user));

			dispatch(sendNotification({
				appearance: "inverted",
				intent: "success",
				message: `Welcome ${user.username}`
			}));
		}
	}, [isSuccess, data, dispatch])

	return (
		<div className={styles.root}>

			{
				!showLogin &&
				<form
					id={`${id}-form`}
					onSubmit={(event: any) => {
						event.preventDefault();
						loginUser();
					}}
				>
					<Field
						label="Username"
						validationMessage={username?.validationMessage}
						validationState={username?.validationState}
					>
						<Input
							id={`${id}-username`}
							onChange={(event, data) => setUsername({ value: data.value })}
						/>
					</Field>

					<Field
						label="Password"
						validationMessage={password?.validationMessage}
						validationState={password?.validationState}
					>
						<Input
							id={`${id}-password`}
							type="password"
							onChange={(event, data) => setPassword({ value: data.value })}
						/>
					</Field>
				</form>
			}

			<div className={styles.buttonContainer}>

				{
					showLogin &&
					<>
						<Button
							type="button"
							onClick={toggleSignUpModal}
							appearance="secondary"
						>
							Sign-Up
						</Button>

						<Button
							type="button"
							onClick={toggleLogin}
							appearance="primary"
						>
							Login
						</Button>
					</>
				}

				{
					!showLogin &&
					<>
						<Button
							type="button"
							onClick={toggleLogin}
							appearance="subtle"
						>
							Cancel
						</Button>

						<Button
							type="submit"
							form={`${id}-form`}
							appearance="primary"
							disabled={isLoading}
						>
							{!isLoading && <>Login</>}
							{isLoading && <Spinner size="extra-small" />}
						</Button>
					</>
				}

			</div>

			{
				showSignUpModal &&
				<NewUser modalIsOpen={showSignUpModal} toggleModal={toggleSignUpModal} />
			}

		</div>
	);

}

export default UnauthenticatedUser;