import { Body1, Body1Strong, Button, Caption1, Caption2, makeStyles, shorthands, Title2, tokens } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { Highlight, Review } from "../Api/Reviews/reviewSlice";
import { useAppSelector } from "../pgStore";
import BoxArt from "../Shared/BoxArt";

const ReviewHeader = (props: { review: Review }) => {
	const styles = useStyles();
	const authorIsCurrentUser: boolean = useAppSelector((state) => state.user.username === props.review.username);
	const navigator = useNavigate();

	return (
		<div className={styles.root}>

			<div className={styles.titleCard}>

				<div style={{ display: "flex", columnGap: "10px" }}>
					<BoxArt
						imageSrc={props.review.videoGameBoxArt}
						altText="Box Art"
						imageOptions={{
							height: "100%"
						}}
						containerOptions={{
							width: "100px",
							height: "100px"
						}}
						rounded
					/>

					<div className={styles.gameTitle}>
						<Title2>{props.review.videoGameTitle}</Title2>
						<Caption1>By {props.review.username}</Caption1>
						<Caption2>Last Updated {props.review.modifiedDate ?? props.review.createDate}</Caption2>
						{
							authorIsCurrentUser && props.review.id &&
							<Button
								appearance="subtle"
								onClick={() => navigator(`/reviews/create?review=${props.review.id}`)}
							>
								Edit
							</Button>
						}
					</div>
				</div>

				<div style={{ display: "flex", alignItems: "center" }}>
					<div className={styles.score}>
						<Title2>{props.review.rating}</Title2>
					</div>
				</div>

			</div>

			{
				props.review.highlights &&
				<div className={styles.tags}>

					{
						props.review.highlights.map((item: Highlight, index: number) => (
							<div key={`${item.name}`} className={styles.tag}>
								<Body1 style={{ margin: "auto" }}>{item.name}</Body1>
								<div className={styles.tagScore}>
									<Body1Strong>{item.rating}</Body1Strong>
								</div>
							</div>
						))
					}

				</div>
			}

		</div>
	);
}

export default ReviewHeader;

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "15px",
		backgroundColor: tokens.colorNeutralBackground2,
		...shorthands.padding("15px"),
		...shorthands.borderRadius("8px"),
		width: "100%",
		top: 0
	},
	titleCard: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	},
	gameTitle: {
		display: "flex",
		flexDirection: "column",
		rowGap: "2px"
	},
	image: {
		width: "100px",
		height: "100px",
		cursor: "pointer"
	},
	tags: {
		display: "flex",
		justifyContent: "left",
		columnGap: "15px"
	},
	tag: {
		display: "flex",
		justifyContent: "space-between",
		columnGap: "3px",
		width: "140px",
		...shorthands.border("1px", "solid", tokens.colorBrandBackground2),
		...shorthands.borderRadius("3px"),
		backgroundColor: tokens.colorBrandBackgroundStatic
	},
	tagScore: {
		backgroundColor: tokens.colorBrandBackground2,
		width: "40px",
		justifyContent: "right",
		textAlign: "center"
	},
	score: {
		backgroundColor: tokens.colorCompoundBrandBackground,
		height: "80px",
		width: "80px",
		...shorthands.borderRadius("40px"),
		paddingTop: "20px",
		textAlign: "center"
	}
});