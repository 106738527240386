import {
	Button,
	Dialog, DialogBody, DialogSurface, DialogTitle, DialogTrigger, DialogContent, DialogActions
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useEffect, useRef } from "react";
import { usePostGameMutation } from "../../Api/Games/gamesSlice";
import { PlatformListItem, VideoGame } from "../../Api/Games/types";
import GameForm, { GameFormRef } from "./GameForm";

export type GameFormModalProps = {
	showModal: boolean,
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
	game?: VideoGame
}

const GameFormModal = (props: GameFormModalProps) => {

	const gameFormRef = useRef<GameFormRef>(null);
	const [saveGame, { isLoading: isSaving, isSuccess }] = usePostGameMutation();

	const onSaveClick = (event: any) => {
		if (gameFormRef.current?.isValid()) {
			const formContext = gameFormRef.current?.getFormValues();
			const videoGame: VideoGame = {
				title: formContext.title.value!, // Bc isValid is true, this value is guaranteed
				description: formContext.description,
				releaseDate: formContext.releaseDate?.value,
				boxArtUrl: formContext.boxArtUrl ? formContext.boxArtUrl.split("/").slice(-1)[0] : undefined,
				availableOnConsoles: formContext.availableConsoles?.map((platform) => ({ name: platform } as PlatformListItem)) ?? [] as PlatformListItem[]
			};
			saveGame(videoGame);
		}
	}

	useEffect(() => {
		if (isSuccess) {
			props.setShowModal(false);
		}
	}, [isSuccess, props]);

	return (
		<Dialog
			open={props.showModal}
			onOpenChange={(event, data) => props.setShowModal(data.open)}
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action="close">
								<Button
									appearance="subtle"
									aria-label="close"
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						{props.game?.title ?? "New Game"}
					</DialogTitle>
					<DialogContent>
						<GameForm
							videoGame={props.game}
							isSaving={isSaving}
							ref={gameFormRef}
						/>
					</DialogContent>
					<DialogActions style={{ paddingTop: "25px" }}>
						<Button
							type='button'
							appearance='secondary'
							onClick={(event) => props.setShowModal(false)}
						>
							Close
						</Button>
						<Button
							type='button'
							appearance='primary'
							style={{ marginLeft: "auto" }}
							onClick={onSaveClick}
							disabled={isSaving}
						>
							Save
						</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);

}

export default GameFormModal;