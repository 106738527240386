import {
	Label, Dropdown, Option, Button,
	Dialog, DialogBody, DialogSurface, DialogActions, DialogContent,
	shorthands, makeStyles
} from "@fluentui/react-components";
import { MutableRefObject, SetStateAction } from "react";
import { useFetchPlatformsQuery } from "../Api/Games/gamesSlice";
import { useAddPlayerPlatformMutation } from "../Api/Players/playersSlice";
import { consoleList } from "../Redux/Types";

const useStyles = makeStyles({
	platformInput: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		maxWidth: "400px",
	},
	platformDialog: {
		width: "400px"
	}
});

export type AddPlatformDialogProps = {
	isOpen: boolean,
	setIsOpen: React.Dispatch<SetStateAction<boolean>>,
	platformRefs: MutableRefObject<Array<HTMLDivElement | null>>,
	playerPlatforms: consoleList[] | undefined,
	username: string
}

const AddPlatformDialog = (props: AddPlatformDialogProps) => {
	const styles = useStyles();
	const { data: platforms } = useFetchPlatformsQuery();
	const [addPlayerPlatform] = useAddPlayerPlatformMutation();

	// event: SelectionEvent data: OptionOnSelectData cant figure out where types come from
	const addPlatform = (event: any, data: any) => {
		if (props.playerPlatforms?.filter((platform) => platform.name === data.optionValue).length === 0) {

			addPlayerPlatform({
				username: props.username,
				body: {
					name: data.optionValue
				}
			});

		}

		props.setIsOpen(false);
		//TODO fix scrolling 
		const platformIndex = props.playerPlatforms?.findIndex(platform => platform.name === data.optionValue);
		if (platformIndex) {
			// You should be able to pass in { behavior: "smooth" } but for some reason its breaking it for me
			props.platformRefs.current[platformIndex]?.scrollIntoView();
		}

	}

	return (
		<Dialog
			open={props.isOpen}
			onOpenChange={(event, data) => props.setIsOpen(data.open)}
		>
			<DialogSurface className={styles.platformDialog}>
				<DialogBody>
					<DialogContent>
						<div className={styles.platformInput}>
							<Label htmlFor='add-console-dropdown'>
								Add Platform
							</Label>
							<Dropdown
								id='add-console-dropdown'
								onOptionSelect={addPlatform}
							>
								{
									platforms?.map((platform, index) =>
										<Option
											key={`${platform.name.replace(' ', '')}-option`}
										>
											{platform.name}
										</Option>
									)
								}
							</Dropdown>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="button"
							appearance="secondary"
							onClick={(event) => props.setIsOpen(false)}
						>
							Cancel
						</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}

export default AddPlatformDialog;