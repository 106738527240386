import { Caption1Stronger, Caption2, Image, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeletePlayerGameMutation } from '../Api/Players/playersSlice';
import { apiPlayerDetailsVideoGame, apiPlayerGameRequest } from '../Redux/Types';

const useStyles = makeStyles({
	root: {
		position: "relative",
		backgroundColor: tokens.colorBrandBackground2,
		minWidth: "135px",
		maxWidth: "135px",
		height: "125px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		textAlign: "center",
		alignItems: "center",
		rowGap: "3px",
		flexWrap: "nowrap",
		...shorthands.padding("3px"),
		...shorthands.borderRadius("5px"),
		":hover": {
			backgroundColor: tokens.colorBrandBackground2Hover,
			cursor: "pointer"
		},
		overflowY: "hidden"
	},
	actions: {
		position: "absolute",
		bottom: "0px",
		width: "100%",
		display: "flex",
		justifyContent: "space-evenly",
		transitionDuration: "0.5s"
	},
	hideActions: {
		position: "absolute",
		bottom: "-25px",
		width: "100%",
		display: "flex",
		justifyContent: "space-evenly",
		transitionDuration: "0.5s"
	},
	reviewBtn: {
		width: "100%",
		borderBottomLeftRadius: "5px",
		backgroundColor: "green",
		":hover": {
			backgroundColor: "lightgreen"
		}
	},
	removeBtn: {
		width: "100%",
		borderBottomRightRadius: "5px",
		backgroundColor: "red",
		":hover": {
			backgroundColor: "orangered"
		}
	}
});

export type PlayerGameCardProps = {
	videoGame: apiPlayerDetailsVideoGame,
	score: number,
	actionsAvailable?: boolean,
	username?: string,
	consoleName?: string
}

const PlayerGameCard = (props: PlayerGameCardProps) => {
	const styles = useStyles();
	const [deleteGame] = useDeletePlayerGameMutation();
	const navigator = useNavigate();

	const reviewClick = () => {
		navigator(`/reviews/create?game=${props.videoGame.title}&platform=${props.consoleName}`)
	}

	const removeClick = () => {
		if (props.username && props.consoleName) {
			const removeGameRequest: { username: string, body: apiPlayerGameRequest } = {
				username: props.username,
				body: {
					name: props.consoleName,
					title: props.videoGame.title
				}
			}

			deleteGame(removeGameRequest);
		}
	}

	const [showActions, setShowActions] = useState(false);

	return (
		<div
			className={styles.root}
			onMouseEnter={() => setShowActions(true)}
			onMouseLeave={() => setShowActions(false)}
			title={props.videoGame.title}
		>

			<Image
				src={`data:image/jpeg;base64,${props.videoGame.boxArt}`}
				alt={`${props.videoGame.title} Box Art`}
				shape="rounded"
				style={{ width: "55px", height: "50px" }}
			/>

			<Caption1Stronger style={{ whiteSpace: "nowrap" }}>
				{
					props.videoGame.title.length > 20 ? `${props.videoGame.title.substring(0, 18)}...` : props.videoGame.title
				}
			</Caption1Stronger>

			<div>
				{
					props.score > 0 &&
					<>
						{[...Array(props.score)].map((x, i) => (
							<FontAwesomeIcon key={props.videoGame.title + '-' + i} icon={faStar} />
						))}
						< FontAwesomeIcon icon={faStarHalf} />
					</>
				}
				{props.score === 0 &&
					<p>Not Reviewed</p>
				}
			</div>

			{
				props.actionsAvailable &&
				<div className={showActions ? styles.actions : styles.hideActions}>
					{
						props.score === 0 &&
						<div className={styles.reviewBtn} onClick={reviewClick}>
							<Caption2>Review</Caption2>
						</div>
					}

					<div className={styles.removeBtn} onClick={removeClick}>
						<Caption2>Remove</Caption2>
					</div>
				</div>
			}

		</div>
	);
}

export default PlayerGameCard;