import { useFetchPlayersQuery } from '../Api/Players/playersSlice';
import {
    Divider,
	makeStyles,
	shorthands,
	Skeleton,
	SkeletonItem,
	tokens
} from "@fluentui/react-components";
import { useState } from 'react';
import { apiPlayerList } from '../Redux/Types';
import Searchbar from '../Shared/Searchbar';
import PlayerItem from './PlayerItem';

import "./Players.scss"

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '18px',
		alignItems: "center"
	},
	itemContainer: {
		backgroundColor: tokens.colorNeutralBackground1,
		maxWidth: "800px",
		minWidth: "300px",
		width: "100%",
		height: "310px"
	},
	skeleton: {
		display: "flex",
		columnGap: "15px",
		...shorthands.padding("15px"),
	},
	skeletonImage: {
		...shorthands.padding("0px"),
		...shorthands.margin("0px"),
		width: "1000px",
		height: "150px"
	}
});

const Players = (props: any) => {
	const styles = useStyles();
	const { data, isLoading } = useFetchPlayersQuery(undefined, { pollingInterval: 30000 });
	const [filteredData, setFilteredData] = useState<apiPlayerList[]>([]);

	return (
		<>
			<h3>Find out what your friends are playing!</h3>
			<Divider />

			<div className={styles.root}>

				{
					isLoading &&
					<>
						<Skeleton style={{ width: "100%" }}>
							<SkeletonItem shape="rectangle" size={36} />
						</Skeleton>

						{
							Array.from(Array(10)).map((item, index) => {
								return (
									<Skeleton key={`skeleton-${index}`} className={styles.itemContainer}>
										<div className={styles.skeleton}>
											<SkeletonItem shape="circle" size={36} />
											<SkeletonItem shape="rectangle" size={36} />
										</div>
										<div className={styles.skeleton}>
											<SkeletonItem shape="rectangle" size={128} className={styles.skeletonImage} />
										</div>
										<div className={styles.skeleton} style={{ justifyContent: "space-between" }}>
											<div style={{ width: "100px" }}>
												<SkeletonItem shape="rectangle" size={32} />
											</div>
											<div style={{ width: "100px" }}>
												<SkeletonItem shape="rectangle" size={32} />
											</div>
										</div>
									</Skeleton>
								)
							})
						}
					</>
				}

				{
					!isLoading &&
					<>
						<Searchbar searchableData={data || []} setSearchableData={setFilteredData} />

						{
							filteredData.map((player, index) =>
								<div key={`player-${index}`} className={styles.itemContainer} >
									<PlayerItem player={player} />
								</div>
							)
						}

					</>
				}

			</div>

		</>

	);
}

export default Players;