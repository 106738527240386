import { SetStateAction, useEffect, useId, useReducer } from 'react';
import {
	Dialog, DialogSurface, DialogTitle, DialogBody, DialogContent, DialogActions,
	Button, Divider, Field, Input,
	makeStyles, shorthands, DialogTrigger, Spinner
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { useFetchPlayersQuery } from '../Api/Players/playersSlice';
import useDebouncer from '../Hooks/useDebouncer';
import { newUserFormReducerActionTypes } from './Types';
import { newUserFormReducers, initialState } from './NewUserFormReducer';
import { apiPlayerDetails, loginRequest } from '../Redux/Types';
import { useCreateLoginMutation, useLoginMutation } from '../Api/User/userSlice';
import { useAppDispatch } from '../pgStore';
import { login } from '../User/userSlice';

export type NewUserProps = {
	modalIsOpen: boolean,
	toggleModal: React.Dispatch<SetStateAction<boolean>>
}

const useStyles = makeStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		...shorthands.gap("2px"),
		maxWidth: "400px",
	},
	dialog: {
		maxWidth: "425px"
	},
	title: {
		display: "flex",
		columnGap: "10px"
	},
	titleText: {
		fontFamily: "'Press Start 2P', sans serif"
	},
	icon: {
		paddingTop: "4px"
	}
});

const NewUser = (props: NewUserProps) => {

	const debounceTimer = 600;
	const id = useId();
	const styles = useStyles();
	const dispatch = useAppDispatch();

	const { data: players } = useFetchPlayersQuery();
	const [addPlayer, { data: newPlayerResult, isSuccess: addIsSuccess }] = useCreateLoginMutation();
	const [attemptLogin, { data: loginResult, isLoading: loginIsLoading, isSuccess: loginIsSuccess }] = useLoginMutation();

	const [formContext, dispatchFormContext] = useReducer(newUserFormReducers, initialState);

	useDebouncer(function validateUsername() {

		if (formContext.username.value) {
			if (formContext.username.value?.length < 4) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "error", validationMessage: "Too Short!" } });
			} else if (formContext.username.value?.length > 15) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "error", validationMessage: "Too Long!" } });
			} else if (formContext.username.value?.includes(" ") || !formContext.username.value?.match("[a-zA-Z0-9_-]+$")) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "error", validationMessage: "Invalid character(s)" } });
			}
			else if (players?.find(x => x.username.localeCompare(formContext.username.value!, undefined, { sensitivity: 'accent' }) === 0)) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "error", validationMessage: "That username is taken!" } });
			}
			else {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "success", validationMessage: undefined } });
			}
		} else {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "none", validationMessage: undefined } });
		}

	}, [formContext.username.value], debounceTimer);

	useDebouncer(function validateEmailAddress() {

		if (formContext.emailAddress.value) {
			if (formContext.emailAddress.value?.length < 4) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "error", validationMessage: "Too Short!" } });
			} else if (formContext.emailAddress.value?.length > 100) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "error", validationMessage: "Too Long!" } });
			}
			else if (players?.find(x => x.emailAddress.localeCompare(formContext.emailAddress.value!, undefined, { sensitivity: 'accent' }) === 0)) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "error", validationMessage: "That email is taken!" } });
			}
			else if (!formContext.emailAddress.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "error", validationMessage: "Email is invalid!" } });
			}
			else {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "success", validationMessage: undefined } });
			}
		} else {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "none", validationMessage: undefined } });
		}

	}, [formContext.emailAddress.value], debounceTimer);

	useDebouncer(function validatePassword() {

		if (formContext.password.value) {
			if (formContext.password.value?.length < 8) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "error", validationMessage: "Too Short!" } });
			} else if (formContext.password.value?.length > 100) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "error", validationMessage: "Too Long!" } });
			}
			else if (!formContext.password.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "error", validationMessage: "Complexity requirement not met. Please use 1 lower case, uppercase, number, and special character" } });
			}
			else {
				dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "success", validationMessage: undefined } });
			}
		} else {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "none", validationMessage: undefined } });
		}

	}, [formContext.password.value], debounceTimer);

	function validateRequiredInputs() {
		let isValid: boolean = true;

		if (formContext.username.validationState !== "success" || formContext.emailAddress.validationState !== "success" || formContext.password.validationState !== "success") {
			isValid = false;
		}

		if (!formContext.username.value) {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "username", validationState: "error", validationMessage: "Required" } });
			isValid = false;
		}

		if (!formContext.emailAddress.value) {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "emailAddress", validationState: "error", validationMessage: "Required" } });
			isValid = false;
		}

		if (!formContext.password.value) {
			dispatchFormContext({ type: newUserFormReducerActionTypes.setInputValidation, payload: { fieldname: "password", validationState: "error", validationMessage: "Required" } });
			isValid = false;
		}

		return isValid;
	}

	const handleSubmit = () => {
		if (validateRequiredInputs()) {
			addPlayer({
				username: formContext.username.value,
				emailAddress: formContext.emailAddress.value,
				password: formContext.password.value
			} as apiPlayerDetails)
		}
	};

	useEffect(() => {
		if (addIsSuccess && newPlayerResult) {
			attemptLogin({ Username: newPlayerResult.username, Password: formContext.password.value } as loginRequest);
		}
	}, [newPlayerResult, addIsSuccess, attemptLogin, formContext.password]);

	useEffect(() => {
		if (loginIsSuccess) {
			let user: apiPlayerDetails = JSON.parse(JSON.stringify(loginResult));
			dispatch(login(user));
			props.toggleModal(false);
		}
	}, [loginIsSuccess, dispatch, loginResult, props]);

	return (
		<Dialog
			open={props.modalIsOpen}
			onOpenChange={(event, data) => props.toggleModal(data.open)}
		>
			<DialogSurface className={styles.dialog}>
				<DialogBody>

					<DialogTitle
						className={styles.title}
						action={
							<DialogTrigger action="close">
								<Button
									appearance="subtle"
									aria-label="close"
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						<FontAwesomeIcon className={styles.icon} icon={faDiceD20} />
						<p className={styles.titleText}>Character Sheet</p>
					</DialogTitle>

					<DialogContent>
						<div
							id={`container-${id}`}
							className={styles.container}
						>
							<Divider />

							<Field
								label="Username"
								validationState={formContext.username.validationState}
								validationMessage={formContext.username.validationMessage}
								required
							>
								<Input
									id={`username-${id}`}
									placeholder="What should we call you?"
									onChange={(event, data) => dispatchFormContext({ type: newUserFormReducerActionTypes.setInputData, payload: { fieldname: "username", value: data.value } })}
								/>
							</Field>

							<Field
								label="Email Address"
								validationState={formContext.emailAddress.validationState}
								validationMessage={formContext.emailAddress.validationMessage}
								required
							>
								<Input
									id={`email-${id}`}
									placeholder="Were not going to send you spam, promise"
									onChange={(event, data) => dispatchFormContext({ type: newUserFormReducerActionTypes.setInputData, payload: { fieldname: "emailAddress", value: data.value } })}
								/>
							</Field>

							<Field
								label="Password"
								validationState={formContext.password.validationState}
								validationMessage={formContext.password.validationMessage}
								required
							>
								<Input
									id={`password-${id}`}
									placeholder="Don't use your bank password here"
									onChange={(event, data) => dispatchFormContext({ type: newUserFormReducerActionTypes.setInputData, payload: { fieldname: "password", value: data.value } })}
									type="password"
								/>
							</Field>

						</div>

					</DialogContent>

					<DialogActions>

						<Button
							type="button"
							appearance="secondary"
							onClick={(event) => props.toggleModal(false)}
						>
							Cancel
						</Button>

						<Button
							type="button"
							appearance="primary"
							onClick={(event) => handleSubmit()}
							disabled={loginIsLoading}
						>
							{!loginIsLoading && <>Start Campaign!</>}
							{loginIsLoading && <Spinner size="extra-small" />}
						</Button>

					</DialogActions>

				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}

export default NewUser;