import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationProps } from '../Layout/Alerts/Notification';

interface alertState {
	notifications: NotificationProps[]
}

const initialState: alertState = {
	notifications: []
}

const alertSlice = createSlice({
	name: 'alerts',
	initialState,
	reducers: {
		sendNotification(state, action: PayloadAction<Omit<NotificationProps, "uniqueId">>) {
			const newNotification = {
				...action.payload,
				uniqueId: Math.floor(Math.random() * 10000)
			} as NotificationProps;

			return {
				...state,
				notifications: [...state.notifications, { ...newNotification }]
			}
		},
		clearNotification(state, action: PayloadAction<number>) {
			const copy = [...state.notifications.filter((notification) => notification.uniqueId !== action.payload)];
			//copy.splice(action.payload, 1);
			return {
				...state,
				notifications: copy
			}
		},
		clearAllNotification(state) {
			return {
				...initialState
			}
		}
	}
});

export const { sendNotification, clearAllNotification, clearNotification } = alertSlice.actions;

export default alertSlice;