import { makeStyles, Title1, Body2, Image, Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import MissingNo from "../Assets/missing.png";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		rowGap: "15px",
		justifyContent: "center",
		alignItems: "center"
	}
});

const NotFound = () => {
	const styles = useStyles();
	const navigator = useNavigate();

	return (
		<div className={styles.root}>
			<Image
				alt="Missing Number"
				src={MissingNo}
				shape="circular"
				bordered
			/>
			<Title1>Not Found</Title1>
			<Body2>What were you expecting?</Body2>
			<Button
				type="button"
				appearance="primary"
				onClick={() => navigator(-1)}
			>
				Back
			</Button>
		</div>
	)
}

export default NotFound;