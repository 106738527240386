import { ChangeEvent, useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { Input } from '@fluentui/react-components';
import useDebouncer from '../Hooks/useDebouncer';

export type SearchbarProps = {
	/** Array of the data that is to be searched over. */
	searchableData: any[],
	/** Dispatch event to update the data  */
	setSearchableData: Dispatch<SetStateAction<any[]>>
}

const Searchbar = (props: SearchbarProps) => {
	// used for searching against dates
	const reISO = /^(\d{4})-(\d{2})-(\d{2})$/;

	const [searchText, setSearchText] = useState("");
	const { searchableData, setSearchableData } = props;

	useEffect(() => {
		setSearchableData(searchableData);
	}, [setSearchableData, searchableData]);

	const searchTextChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	}, []);

	useDebouncer(() => {
		function filterByValue(data: any[], searchValue: string) {
			// Dont initiate search until we have enough to use
			if (searchValue.length >= 3) {
				return data.filter(obj => Object.keys(obj).some(key => {
					let searchableValue;
					if (typeof (obj[key]) === 'string') {
						if (reISO.exec(obj[key])) {
							// Key was a date
							searchableValue = new Date(obj[key] + "00:00:00").toLocaleDateString("en-US");
						} else {
							searchableValue = obj[key];
						}
					} else {
						// Cast as string
						searchableValue = String(obj[key]);
					}

					return searchableValue.toLowerCase().includes(searchValue.toLowerCase());
				}));
			}

			return data;
		}

		props.setSearchableData(filterByValue(props.searchableData, searchText));

	}, [searchText], 500);

	return (
		<Input
			type="text"
			value={searchText}
			onChange={searchTextChanged}
			placeholder="Search..."
			style={{ width: '100%' }}
			appearance="underline"
		/>
	);
}

export default Searchbar;