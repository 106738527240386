import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiPlayerDetails } from '../Redux/Types';

interface userState {
	authenticated: boolean,
	username: string | undefined, 
	emailAddress: string | undefined
}

const initialState: userState = {
	username: undefined,
	emailAddress: undefined,
	authenticated: false
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login(state, action: PayloadAction<apiPlayerDetails>) {
			if (action.payload) {
				return {
					...action.payload,
					authenticated: true
				}
			}
		},
		logout(state) {
			return { ...initialState }
		}
	}
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;