function Faq(): any {

    return (
        <>
            <h3>Frequently Asked Questions</h3>
            <hr />
            <p>Under Construction, theres probably a lot of questions right now.</p>
        </>

    );
}

export default Faq;