import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Platform } from '../Media/Types';
import { useFetchPlayerGamesQuery } from '../Api/Players/playersSlice';
import { apiPlayerGameFetchRequest } from '../Redux/Types';
import PlayerGameCard from './PlayerGameCard';
import {
	Button, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuProps, Title3, makeStyles, Divider
} from "@fluentui/react-components";
import { MoreCircle48Filled } from "@fluentui/react-icons";
import AddPlayerGameModal from './Form/AddPlayerGameModal';

import "./Profile.scss";
import RemovePlatformDialog from './RemovePlatformDialog';

const useStyles = makeStyles({
	icon: {
		color: "#fff"
	}
});

/** Props for the GameLibrary component */
export type GameLibraryProps = {
	/** Username of the Player profile page open */
	username: string,
	/** Platform instance representing the Console the component is displaying */
	console: Platform,
	/** Boolean determining if the page is editable, meaning its the currently logged in users profile */
	isEditable?: boolean | undefined
}

/** Component used for the Profile page to display the Players owned games for a specified console */
const GameLibrary = (props: GameLibraryProps) => {
	const styles = useStyles();

	const {
		data: games,
		isLoading: fetchingGames,
		isError: fetchingGamesHasError,
		error: fetchingGameError
	} = useFetchPlayerGamesQuery({ username: props.username, name: props.console.name } as Partial<apiPlayerGameFetchRequest>);

	const [showAddGameModal, setShowAddGameModal] = useState(false);
	const toggleModal = () => setShowAddGameModal(!showAddGameModal);

	const [showDelPlatformModal, setDelPlatformModal] = useState(false);
	const togglePlatformModal = () => setDelPlatformModal(!showDelPlatformModal);

	const [showMenu, setShowMenu] = useState(false);
	const onMenuOpenChange: MenuProps["onOpenChange"] = (event, data) => {
		setShowMenu(data.open);
	}

	return (

		<div className="profile-library-container">

			<div className="profile-library-header">

				<div style={{ display: 'flex', paddingLeft: '15px', alignItems: 'center', columnGap: '7px' }} >
					<FontAwesomeIcon icon={props.console.FaIconName} className={styles.icon} />
					<Title3>{props.console.name}</Title3>
				</div>

				{
					props.isEditable === true &&
					<div style={{ paddingRight: '15px' }}>
						<Menu
							open={showMenu}
							onOpenChange={onMenuOpenChange}
						>
							<MenuTrigger>
								<Button
									appearance="transparent"
									icon={<MoreCircle48Filled />}
								/>
							</MenuTrigger>

							<MenuPopover>
								<MenuList>
									<MenuItem onClick={toggleModal}>Add Game</MenuItem>
									<MenuItem onClick={togglePlatformModal}>Remove Console</MenuItem>
								</MenuList>
							</MenuPopover>
						</Menu>
					</div>
				}

			</div>

			<Divider />

			{
				games &&
				<div className="profile-game-cards-container">
					{
						!fetchingGames && games && games.length > 0 && games.map((game, index) => (
							<PlayerGameCard
								key={game.title.replace(" ", "") + '-' + index + '-library'}
								videoGame={game}
								score={0}
								actionsAvailable={props.isEditable}
								username={props.username}
								consoleName={props.console.name}
							/>

						))
					}
					{
						!fetchingGames && (!games || games.length === 0) &&
						<p style={{ color: "#000", margin: "auto" }}>No Games Added Yet!</p>
					}
					{
						!fetchingGames && fetchingGamesHasError &&
						<p>{JSON.stringify(fetchingGameError)}</p>
					}
				</div>
			}

			<AddPlayerGameModal
				showModal={showAddGameModal}
				setShowModal={setShowAddGameModal}
				platform={props.console}
				playerGames={games}
			/>

			<RemovePlatformDialog
				isOpen={showDelPlatformModal}
				setIsOpen={setDelPlatformModal}
				username={props.username}
				console={props.console}
			/>

		</div>

	);
}

export default GameLibrary;