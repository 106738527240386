import {
	Button, Dialog, Dropdown, DialogBody, DialogSurface, DialogContent, DialogActions, Label, Input, Option, makeStyles
} from "@fluentui/react-components";
import { SetStateAction, useState } from "react";
import { useAddPlayerSocialMutation } from "../Api/Players/playersSlice";
import { useFetchSocialPlatformsQuery } from "../Api/Socials/socialSlice";

const useStyles = makeStyles({
	form: {
		display: "flex",
		flexDirection: "column",
		rowGap: "12px"
	}
});

export type AddSocialDialogProps = {
	isOpen: boolean,
	setIsOpen: React.Dispatch<SetStateAction<boolean>>,
	username: string
}

const AddSocialDialog = (props: AddSocialDialogProps) => {
	const styles = useStyles();
	const [socialPlatform, setSocialPlatform] = useState(0);
	const [socialUsername, setSocialUsername] = useState("");
	const { data: socialPlatforms } = useFetchSocialPlatformsQuery();
	const [addSocialPlatform] = useAddPlayerSocialMutation();

	const onSaveClick = (event: any) => {
		addSocialPlatform({ id: socialPlatform, socialUsername: socialUsername, username: props.username });
		props.setIsOpen(false);
	}

	return (
		<Dialog
			open={props.isOpen}
			onOpenChange={(event, data) => props.setIsOpen(data.open)}
		>
			<DialogSurface>
				<DialogBody>
					<DialogContent>
						<div className={styles.form}>
							<Label htmlFor="socialPlatform">Platform</Label>
							<Dropdown
								id="socialPlatform"
								appearance="underline"
								onOptionSelect={(event, data) => setSocialPlatform(Number(data.optionValue))}
							>
								{
									socialPlatforms && socialPlatforms.map((item, index) =>
										<Option
											key={item.name}
											value={String(item.id)}
										>
											{item.name}
										</Option>
									)
								}
							</Dropdown>

							<Label htmlFor="socialUsername">Username</Label>
							<Input
								id="socialUsername"
								type="text"
								appearance="underline"
								disabled={socialPlatform === 0}
								value={socialUsername}
								onChange={(event) => setSocialUsername(event.target.value)}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="button"
							appearance="secondary"
							onClick={() => props.setIsOpen(false)}
						>
							Cancel
						</Button>
						<Button
							type="button"
							appearance="primary"
							onClick={onSaveClick}
							disabled={(socialPlatform === 0 || socialUsername === "")}
						>
							Save
						</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

export default AddSocialDialog;