import { consoleList } from "../../Redux/Types";
import { PeerGamingApiSlice } from "../PeerGamingApiSlice";
import { VideoGame, VideoGameListItem } from "./types";

export const gamesSlice = PeerGamingApiSlice.injectEndpoints({
	endpoints(builder) {
		return {

			fetchGames: builder.query<VideoGameListItem[], { console?: string, gameTitleStartsWith: string } | undefined>({
				query: (request) => ({
					url: '/games',
					params: {
						consoleName: request?.console,
						gameTitleStartsWith: request?.gameTitleStartsWith
					}
				}),
				providesTags: ['Games'],
				transformResponse: (result: VideoGameListItem[], meta, args) => result.sort((left, right) => left.title.localeCompare(right.title))
			}),

			fetchGameDetails: builder.query<VideoGame, string>({
				query: (title) => ({
					url: `/games/${title}`
				}),
				providesTags: (result, error, id) => [{ type: 'Games', id }]
			}),

			postGame: builder.mutation<VideoGame, VideoGame>({
				query: (body) => ({
					url: '/games',
					method: 'POST',
					body: {
						...body,
						availableOnConsoles: body.availableOnConsoles.map((platform) => platform.name)
					}
				}),
				invalidatesTags: ['Games']
			}),

			fetchPlatforms: builder.query<consoleList[], void>({
				query() {
					return '/platforms'
				},
				providesTags: ['Platforms']
			}),

			addBoxArtImage: builder.mutation<{ url: string }, Partial<FormData>>({
				query: (request) => ({
					url: '/games/boxart/create',
					method: 'POST',
					body: request
				})

			})
		}
	}
});

export const {
	useFetchGamesQuery, useFetchGameDetailsQuery,
	usePostGameMutation, useFetchPlatformsQuery, useAddBoxArtImageMutation } = gamesSlice;