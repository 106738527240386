import { PeerGamingApiSlice } from "../PeerGamingApiSlice";
import { SocialPlatform } from "./types";

export const socialApi = PeerGamingApiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			fetchSocialPlatforms: builder.query<SocialPlatform[], void>({
				query: () => ({
					url: '/socials'
				}),
				providesTags: ['Socials']
			})
		}
	}
});

export const { useFetchSocialPlatformsQuery } = socialApi;