import { GameFormAction, GameFormActionType, GameFormState } from "../types";

export function gameFormReducer(state: GameFormState, action: GameFormAction): GameFormState {
	switch (action.type) {
		case GameFormActionType.SetTitle:
			return {
				...state,
				title: {
					...state.title,
					...action.payload
				}
			}
		case GameFormActionType.SetBoxArtUrl:
			return {
				...state,
				boxArtUrl: action.payload
			}
		case GameFormActionType.SetDescription:
			return {
				...state,
				description: action.payload
			}
		case GameFormActionType.SetReleaseDate:
			return {
				...state,
				releaseDate: {
					...state.releaseDate,
					...action.payload
				}
			}
		case GameFormActionType.SetAvailableConsoles:
			return {
				...state,
				availableConsoles: [...action.payload]
			}
		default:
			return state;
	}
}

export function isValid(state: GameFormState, dispatch: React.Dispatch<GameFormAction>): boolean {
	let isValid = true;
	console.log(state.title.value);
	if (!state.title.value) {
		dispatch({ type: GameFormActionType.SetTitle, payload: { validationState: "error", validationMessage: "Title is required." } });
		isValid = false;
	}

	if (!state.releaseDate?.value) {
		dispatch({ type: GameFormActionType.SetReleaseDate, payload: { validationState: "error", validationMessage: "Release Date is required." } });
		isValid = false;
	}

	return isValid;
}