import {
	Button, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuProps,
	LargeTitle, makeStyles, shorthands, tokens
} from "@fluentui/react-components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../pgStore";
import { MoreCircle48Filled } from "@fluentui/react-icons";
import { useState } from "react";
import AddSocialDialog from "./AddSocial";

const useStyles = makeStyles({
	root: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: tokens.colorNeutralBackground1,
		...shorthands.borderRadius("5px")
	}
});

export type PlayerHeaderProps = {
	username: string,
	isEditable: boolean
}

const PlayerHeader = (props: PlayerHeaderProps) => {
	const styles = useStyles();
	const loggedInUsername = useAppSelector((state) => state.user.username);
	const [showMenu, setShowMenu] = useState(false);
	const [showAddSocial, setShowAddSocial] = useState(false);

	const onMenuOpenChange: MenuProps["onOpenChange"] = (event, data) => {
		setShowMenu(data.open);
	}

	return (
		<>
			<div className={styles.root}>
				<div className="profile-player-icon">

					<div className="profile-play-icon-img">
						<img src="/Peer-Gaming-Logo-Minimized.png" title="Profile" alt=""></img>
					</div>

					<LargeTitle>{props.username}</LargeTitle>

				</div>

				{
					// If there is a logged in user and this isn't their profile they can Follow
					loggedInUsername && !props.isEditable &&
					<Button
						type="button"
						className="btn btn-success"
						appearance="primary"
						disabled
						title="Coming Soon"
					>
						<FontAwesomeIcon icon={faPlus} />
						Follow
					</Button>
				}

				{
					loggedInUsername && props.isEditable &&
					<>
						<Menu
							open={showMenu}
							onOpenChange={onMenuOpenChange}
						>
							<MenuTrigger>
								<Button
									appearance="transparent"
									icon={<MoreCircle48Filled />}
								/>
							</MenuTrigger>

							<MenuPopover>
								<MenuList>
									<MenuItem onClick={() => setShowAddSocial(true)}>Add Social Platform</MenuItem>
									<MenuItem disabled>Change Profile Icon</MenuItem>
									<MenuItem disabled>Change Cover Image</MenuItem>
								</MenuList>
							</MenuPopover>
						</Menu>
					</>
				}
			</div>

			{
				showAddSocial &&
				<AddSocialDialog
					isOpen={showAddSocial}
					setIsOpen={setShowAddSocial}
					username={props.username}
				/>
			}
		</>
	)
}

export default PlayerHeader;