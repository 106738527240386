import { Alert } from "@fluentui/react-components/unstable";
import { DismissCircleRegular } from "@fluentui/react-icons";
import { useAppDispatch } from "../../pgStore";
import { clearNotification } from "../../Alerts/alertSlice";

export type NotificationProps = {
	appearance: "primary" | "inverted",
	intent: "success" | "error" | "warning" | "info",
	message: string,
	uniqueId: number
}

const Notification = (props: { notification: NotificationProps }) => {
	const dispatch = useAppDispatch();

	const timeout = setTimeout(() => {
		removeNotification();
	}, 10000);

	const removeNotification = () => {
		dispatch(clearNotification(props.notification.uniqueId));
		clearTimeout(timeout);
	}

	return (
		<Alert
			appearance={props.notification.appearance}
			intent={props.notification.intent}
			action={{
				icon: <DismissCircleRegular />,
				onClick: () => { removeNotification() }
			}}
		>
			{props.notification.message}
		</Alert>
	);
}

export default Notification;