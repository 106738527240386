import { makeStyles } from "@fluentui/react-components";
import { useAppSelector } from "../../pgStore";
import Notification from "./Notification";

const useStyles = makeStyles({
	root: {
		position: "absolute",
		bottom: "10px",
		right: "20px",
		display: "flex",
		flexDirection: "column-reverse",
		rowGap: "3px",
		opacity: "0.9",
		fontFamily: "'Montserrat', sans-serif",
		minWidth: "250px",
		width: "460px",
		maxHeight: "200px",
		overflowY: "auto",
		zIndex: 10000
	}
});

/*
	Maybe switch the key from using the index, that may be causing it to always re-render
*/


const NotificationHub = () => {
	const styles = useStyles();
	const alertState = useAppSelector((state) => state.alerts);

	return (
		<div className={styles.root}>
			{
				alertState.notifications.map((item, index) => { 
					return (
						<Notification key={`notification-${item.uniqueId}`} notification={item} />
					)
				})
			}
		</div>
	);
}

export default NotificationHub;