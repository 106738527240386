import {
	Button, Persona, Caption2, Caption1Stronger, Divider,
	Menu, MenuTrigger, MenuList, MenuPopover, MenuItem, MenuGroup, MenuGroupHeader,
	Popover, PopoverTrigger, PopoverSurface
} from '@fluentui/react-components';
import { Bug24Filled, Megaphone24Filled } from '@fluentui/react-icons';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../pgStore';
import { logout } from '../../User/userSlice';

const AuthenticatedUser = () => {
	const currentUser = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const navigator = useNavigate();

	function goToProfile() {
		let profileRoute = `/players/${currentUser?.username}`;
		navigator(profileRoute);
	}

	const onBugClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		console.log('bug');
	}

	return (
		<div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
			<Button
				type="button"
				appearance="subtle"
				icon={<Bug24Filled />}
				onClick={onBugClick}
				title="Report a Bug"
				style={{ height: "100%", paddingLeft: "30px", paddingRight: "30px" }}
			>

			</Button>

			<Popover>
				<PopoverTrigger>
					<Button
						type="button"
						appearance="subtle"
						icon={<Megaphone24Filled />}
						onClick={onBugClick}
						title="Announcements"
						style={{ height: "100%", paddingLeft: "30px", paddingRight: "30px" }}
					>

					</Button>
				</PopoverTrigger>

				<PopoverSurface>
					<div>
						<Caption1Stronger>Version 1.0.0</Caption1Stronger>
						<Divider />
						<Caption2>Updates coming!</Caption2>
					</div>
				</PopoverSurface>
			</Popover>


			<Menu>
				<MenuTrigger disableButtonEnhancement>
					<Button
						type="button"
						appearance="subtle"
					>
						<Persona
							size="large"
							name={currentUser.username}
							primaryText=""
							avatar={{ color: "brand" }}
							style={{ paddingLeft: "12px" }}
						/>
					</Button>
				</MenuTrigger>

				<MenuPopover>
					<MenuList>
						<MenuGroup>
							<MenuGroupHeader>{currentUser.username}</MenuGroupHeader>
							<MenuItem onClick={() => goToProfile()}>Profile</MenuItem>
							<MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
						</MenuGroup>
					</MenuList>
				</MenuPopover>
			</Menu>
		</div>
	);
}

export default AuthenticatedUser;