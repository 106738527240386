import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { useDeletePlayerPlatformMutation } from '../Api/Players/playersSlice';
import { apiPlayerConsoleGamesRequest } from '../Redux/Types';
import {
	Button, Dialog, DialogBody, DialogSurface, DialogTitle, DialogTrigger, DialogContent, DialogActions
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Platform } from '../Media/Types';

export type RemovePlatformDialogProps = {
	isOpen: boolean,
	setIsOpen: Dispatch<SetStateAction<boolean>>,
	username: string,
	console: Platform
}

const RemovePlatformDialog = (props: RemovePlatformDialogProps) => {

	const [deletePlatform] = useDeletePlayerPlatformMutation();
	const deletePlatformRequest: { username: string, body: apiPlayerConsoleGamesRequest } = {
		body: {
			name: props.console.name
		},
		username: props.username
	};

	return (
		<Dialog
			open={props.isOpen}
			onOpenChange={(event, data) => props.setIsOpen(data.open)}
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action="close">
								<Button
									appearance="subtle"
									aria-label="close"
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						<>
							<FontAwesomeIcon style={{ paddingRight: '5px' }} icon={props.console.FaIconName} />
							Remove {props.console.name}
						</>
					</DialogTitle>
					<DialogContent>
						<div>
							Are you sure you want to remove {props.console.name} from your library?
							<div style={{ fontSize: '9px' }}>
								*This will remove all games you own for the console and will have to be readded if you add back the platform.
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="button"
							appearance="primary"
							onClick={(event) => {
								deletePlatform(deletePlatformRequest);
								props.setIsOpen(false);
							}}
						>
							Yes
						</Button>
						<Button
							type="button"
							appearance="secondary"
							onClick={(event) => props.setIsOpen(false)}
						>
							No
						</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

export default RemovePlatformDialog;