import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { routes } from "./Routes"

const ApplicationRouter = () => {
	const router = createBrowserRouter(routes);

	return (
		<RouterProvider router={router} />
	);
}

export default ApplicationRouter;