import { Caption1, Image, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import React from "react";

const useStyles = makeStyles({
	root: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: tokens.colorNeutralBackground5,
		...shorthands.padding("15px")
	},
	reviews: {
		display: "flex",
		columnGap: "3px",
		alignItems: "center"
	},
	image: {
		height: "50px",
		width: "50px",
		cursor: "pointer"
	}
});

const ReviewFooter = () => {
	const styles = useStyles();

	return (
		<div className={styles.root}>
			<div className={styles.reviews}>
				<Caption1>More Sneaky Squid Reviews</Caption1>
				<Image
					alt="Alt Review 1"
					className={styles.image}
					bordered
					shape="rounded"
				/>

				<Image
					alt="Alt Review 2"
					className={styles.image}
					bordered
					shape="rounded"
				/>

				<Image
					alt="Alt Review 3"
					className={styles.image}
					bordered
					shape="rounded"
				/>
			</div>
			<div className={styles.reviews}>
				<Caption1>More Guardians of the Galaxy Reviews</Caption1>
				<Image
					alt="Alt Game 1"
					className={styles.image}
					bordered
					shape="rounded"
				/>

				<Image
					alt="Alt Game 2"
					className={styles.image}
					bordered
					shape="rounded"
				/>

				<Image
					alt="Alt Game 3"
					className={styles.image}
					bordered
					shape="rounded"
				/>
			</div>
		</div>
	);
}

export default ReviewFooter;