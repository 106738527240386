import { useFetchGamesQuery } from '../Api/Games/gamesSlice';
import GameCard, { GameCardRef } from './GameCard';
import { Platform } from './Types';
import { createRef, MouseEvent, RefObject, useEffect, useId, useState } from 'react';
import { apiPlayerGameRequest, apiPlayerDetailsVideoGame, apiVideoGameList, apiFetchVideoGameListRequest } from '../Redux/Types';
import { useAddPlayerGameMutation } from '../Api/Players/playersSlice';
import { useAppSelector } from '../pgStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Input, Spinner } from '@fluentui/react-components';
import useDebouncer from '../Hooks/useDebouncer';

import "./Media.scss";


export type PlatformLibraryProps = {
	platform: Platform,
	currentPlayersLibrary?: apiPlayerDetailsVideoGame[]
}

/** Component to display and add video games for a specific console. */
const PlatformLibrary = (props: PlatformLibraryProps) => {
	const id = useId();
	const [searchText, setSearchText] = useState("");
	const [addingGame, setAddingGame] = useState(false);
	const cardRefs = [] as RefObject<GameCardRef>[];
	const currentUser = useAppSelector((state) => state.user);

	const [addPlayerGame, { isSuccess: addSuccess }] = useAddPlayerGameMutation();
	const [fetchGamesRequest, setFetchGamesRequest] = useState<apiFetchVideoGameListRequest>({console: props.platform.name} as apiFetchVideoGameListRequest);
	const { data, isLoading, error, isError } = useFetchGamesQuery(fetchGamesRequest);

	useEffect(function addPlayerGame() {
		// Do something successful
	}, [addSuccess]);

	useEffect(() => {
		if (isError) {
			alert(JSON.stringify(error));
		}
	}, [isError, error]);

	const onGameClick = (event: MouseEvent<HTMLDivElement>, index: number, game: apiVideoGameList) => {

		const gameRequest = {
			name: props.platform.name,
			title: game.title
		} as apiPlayerGameRequest;

		// Only add the game to their library if they dont have it
		if (currentUser.username && index !== undefined && !props.currentPlayersLibrary?.some(x => x.title === game.title && x.console.name === props.platform.name)) {
			addPlayerGame({ username: currentUser.username, body: gameRequest });
			cardRefs[index].current?.triggerFeedback();
		}

	}

	useDebouncer(() => {
		setFetchGamesRequest({
			...fetchGamesRequest,
			gameTitleStartsWith: searchText
		});
	}, [searchText], 500);

	return (
		<>

			{
				!addingGame &&
				<>
					<Input
						id={`${id}-search`}
						type="text"
						placeholder="Search..."
						onChange={(event, data) => setSearchText(data.value)}
						style={{width:"100%"} }
					/>

					<div className="platform-game-container">
						{
							isLoading &&
							<Spinner />
						}
						{
							!isLoading && data?.map((game, index) => {
								if (index > 5) {
									return false;
								}
								// Create a ref for each game card and push into array
								const gameCardRef = createRef<GameCardRef>();
								cardRefs.push(gameCardRef);

								return (
									<GameCard key={index} game={game} cardClickEvent={onGameClick} feedbackText="Added to Library" gameOwnedByPlayer={props.currentPlayersLibrary?.some(x => x.title === game.title)} ref={gameCardRef} index={index} />
								)
							})
						}
					</div>
					<div className="platform-game-new-container">
						<>
							Don't see the game you're looking for? Add it to our library
							<FontAwesomeIcon icon={faPlusSquare} className="ico-success" onClick={(event) => setAddingGame(true)} />
						</>
					</div>

				</>
			}

		</>
	);
}

export default PlatformLibrary;