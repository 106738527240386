import InfoCard from "../Shared/InfoCard";
import { InfoCardPostType } from "../Shared/Types";

// Need to dynamically import images once these are pulled from the database
import SnapImage from '../Assets/StoryCards/marvel-snap-card.jpg';
import GoWImage from '../Assets/StoryCards/god-of-war-ragnarok.jpg';
import Ow2Image from '../Assets/StoryCards/overwatch-2.jpg';

import '../Home/Home.scss';
import { Divider, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '25px',
		justifyContent: "space-around",
		alignItems: "center"
	}
});

function Home(): any {

	const styles = useStyles();

	return (
		<div className={styles.root}>
			<h3>Welcome to Peer Gaming</h3>
			<Divider />

			<InfoCard
				types={[InfoCardPostType.Hot]}
				title="God of War: Ragnorok"
				body="Kratos and Atreus are back and ready to slaughter the remaining Norse gods!"
				imageSource={GoWImage}
			/>

			<InfoCard
				types={[InfoCardPostType.NewRelease]}
				title="Marvel Snap"
				body="The newest mobile app from Marvel is a fast paced CCG featuring awesome art work of your favorite heroes!"
				imageSource={SnapImage}
			/>

			<InfoCard
				types={[InfoCardPostType.Update, InfoCardPostType.Hot]}
				title="Overwatch 2"
				body="New Tank Hero dropping with the December patch, Ramattra. Watch the origin story now!"
				imageSource={Ow2Image}
			/>

		</div>

	);
}

export default Home;